import logoWO from "../../img/winoui_logo.png";
import logoMX from "../../img/madnix_logo.png";
import logoWS from "../../img/wildsultan_logo.png";

export const formattedDate = new Date().toISOString().split("T")[0].replace(/-/g, "/");

export const PROMO_STEPS = [
    {
        index: 1,
        name: "Brand and method",
        subTitle: "Choose the brand and method"
    },
    {
        index: 2,
        name: "Main information",
        subTitle: "Add main information"
    },
    {
        index: 3,
        name: "Output",
        subTitle: "Copy output information"
    },
];

export const PROMO_BRANDS = [
    {
        index: 1,
        title: "WinOui - One Time Promotions",
        name: "WinOui",
        url: "www.winoui.com",
        logo: logoWO,
        isEnable: true,
    },
    {
        index: 2,
        title: "WinOui - Recurring Promotions",
        name: "WinOuiLow",
        url: "www.winoui.com",
        logo: logoWO,
        isEnable: true,
    },
    {
        index: 3,
        title: "MadNix",
        name: "MadNix",
        url: "www.madnix.com",
        logo: logoMX,
        isEnable: true,
    },
    {
        index: 4,
        title: "Wild Sultan",
        name: "WildSultan",
        url: "www.wildsultan.com",
        logo: logoWS,
        isEnable: true,
    },
];
