const InputBrand = ({ index, name, title, url, logo, isEnable, handleChange }) => {
    return (
        <li className={`gchoice_1_${index-1} col-3 ${!isEnable ? "disabled" : ""}`}>
            <input
                className={!isEnable ? "disabled" : ""}
                name="input_34"
                type="radio"
                value={name}
                id={`choice_1_${index-1}`}
                tabIndex={index}
                formvaluetype="brand"
                onChange={handleChange}
            />
            <label
                htmlFor={`choice_1_${index-1}`}
                id={`label_1_${index-1}`}
                className="d-flex"
            >
                <img src={logo} alt="logo" className="logo" />
                <div className="label-description d-flex flex-column justify-content-center align-items-baseline">
                    <span className="label-title">{title}</span>
                    <span className="link">{url}</span>
                </div>
            </label>
        </li>
    );
};

export default InputBrand;
