import { PROMO_BRANDS } from "../../constants/promotionGenerator/datas";
import CodePreview from "../common/CodePreview";
import PrevButton from "../common/PrevButton";
import NextButton from "../common/NextButton";
import { useState, useRef, useEffect } from "react";
import copyLogo from "../../img/copy_logo.svg";

const ResultStage = ({ selectedBrand, currentStage, handleNextStep, generatedCodeEn, generatedCodeFr, currentGenerator, onUpdate }) => {
    const showCurrentStage = currentStage === 3 ? "d-flex" : "d-none";
    const selectedBrandLogo = PROMO_BRANDS.find(({ name }) => name === selectedBrand)?.logo;
    const [copySuccessEn, setCopySuccessEn] = useState("Copy code");
    const [copySuccessFr, setCopySuccessFr] = useState("Copy code");
    const timerRef = useRef(null);

    useEffect(() => {
        return () => {
            clearTimeout(timerRef?.current);
        };
    }, []);

    const copyToClipboard = (e, selector, setLabel) => {
        clearTimeout(timerRef?.current);
        const element = document.querySelector(selector);
        element.select();
        document.execCommand("copy");
        e.target.focus();
        setLabel("Code copied!");
        timerRef.current = setTimeout(() => {
            setLabel("Copy code");
        }, 2000);
        document.getSelection().removeAllRanges();
    };

    return (
        <ul
            id="gform_fields_3"
            className={`gform_fields top_label form_sublabel_below description_below ${showCurrentStage} flex-column`}
        >
            <label className="gfield_label"><img src={selectedBrandLogo} alt="WO logo"
                                                 className="logo"/> Output</label>
            <label className="input-title">Content <span>(en)</span>
                <span className="helper-icon"
                      onClick={(e) => copyToClipboard(e,".generatedCodeEn", setCopySuccessEn)}>
                    <img src={copyLogo} alt="copy"/> {copySuccessEn}</span>
            </label>
            <CodePreview
                resultCode={generatedCodeEn}
                codeLang={"generatedCodeEn"}
            />
            <label className="input-title">Content <span>(fr)</span>
                <span className="helper-icon"
                      onClick={(e) => copyToClipboard(e,".generatedCodeFr", setCopySuccessFr)}>
                    <img src={copyLogo} alt="copy"/> {copySuccessFr}</span>
            </label>
            <CodePreview
                resultCode={generatedCodeFr}
                codeLang={"generatedCodeFr"}
            />
            <li className="next-button copy-button">
                <PrevButton
                    prevStep={2}
                    handleNextStep={handleNextStep}
                />
                <NextButton
                    currentGenerator={currentGenerator}
                    onUpdate={onUpdate}
                    isStepValid={true}
                    nextStep={"done"}
                    handleNextStep={handleNextStep}
                    label={"New Block Generation"}
                    type="button"
                />
            </li>
        </ul>
    )
}

export default ResultStage;
