import logoWO from "../../img/winoui_logo.png";
import logoMX from "../../img/madnix_logo.png";
import logoWS from "../../img/wildsultan_logo.png";

export const BANNER_STEPS = [
    {
        index: 1,
        name: "Brand and purpose",
        subTitle: "Choose casino brand and set banner purpose"
    },
    {
        index: 2,
        name: "Banner Settings",
        subTitle: "Set up banner links and foreground alignment"
    },
    {
        index: 3,
        name: "Result",
        subTitle: "Get generated HTML code of banner"
    },
];

export const BANNER_BRANDS = [
    {
        index: 1,
        title: "WinOui",
        name: "WinOui",
        url: "www.winoui.com",
        logo: logoWO,
        isEnable: true,
    },
    {
        index: 2,
        title: "MadNix",
        name: "MadNix",
        url: "www.madnix.com",
        logo: logoMX,
        isEnable: true,
    },
    {
        index: 3,
        title: "Wild Sultan",
        name: "WildSultan",
        url: "www.wildsultan.com",
        logo: logoWS,
        isEnable: true,
    },
];

export const PURPOSES = [
    {
        index: 1,
        name: "Promotion",
    },
    {
        index: 2,
        name: "Game",
    },
];

export const LANGS = [
    {
        index: 1,
        name: "EN",
        brand: ["WinOui", "MadNix", "WildSultan"]
    },
    {
        index: 2,
        name: "FR",
        brand: ["WinOui", "MadNix", "WildSultan"]
    },
    {
        index:3,
        name: "DE",
        brand: ["WildSultan"]
    },
    {
        index:4,
        name: "FI",
        brand: ["WildSultan"]
    }
];
