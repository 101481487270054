import bannerIcon from "../../img/banner-gen-icon.svg";
import promoIcon from "../../img/promo-gen-icon.svg";

const SwitchGenerator = ({onUpdate, currentGenerator}) => {

    const changeGenerator = (current) => {
        onUpdate(current);
    };

    return (
        <div className="switch-generator">
            <div
                className={`banner-generator switch-generator-item ${currentGenerator === "bannerGenerator" ? "active" : "inactive"}`}
                onClick={() => changeGenerator("bannerGenerator")}>
                <img src={bannerIcon} alt="banner icon"/>
                <span>Banner generator</span>
            </div>
            <div
                className={`promo-generator switch-generator-item ${currentGenerator === "promoGenerator" ? "active" : "inactive"}`}
                onClick={() => changeGenerator("promoGenerator")}>
                <img src={promoIcon} alt="promo icon"/>
                <span>Promo block generator</span>
            </div>
        </div>
    )
}

export default SwitchGenerator;
