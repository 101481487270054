const CodePreview = ({ codeLang = "", resultCode }) => {
    return (
        <li className="banner__wrapper">
            <div className="code_section generatedCodeContainer">
                <textarea
                    className={`generatedCode ${codeLang}`}
                    value={resultCode}
                    readOnly={true}
                />
            </div>
        </li>
    );
}

export default CodePreview;
