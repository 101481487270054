export var GENERATED_CODE_PROMO_WO = `<div class="slider_bg" id="%PAGE_NAME%-%LANG%" data-url="/%LANG%/promotions/[promotionName]" data-url-as="/%LANG%/promotions/%PAGE_NAME%">
<div class="container" style="display: flex; height:100%; width: 100%"> 
      <img id="%PAGE_NAME%-%LANG%-image" src="%FOREGROUND_URL%" alt="%TAG%" style="max-width: fit-content; max-height: 100%; justify-content: flex-end; align-self: flex-end; object-fit: scale-down; object-position: bottom;">
  </div>
</div>

<style>
#%PAGE_NAME%-%LANG% {
  background-image: url("%WEBP%");
}

@media only screen and (max-width: 1120px) {
  #%PAGE_NAME%-%LANG% {
      background-size: 100% 101%!important;
      background-position: left bottom!important;
  }
}

@-moz-document url-prefix() {
@media only screen and (min-width: 1024px) {
  #%PAGE_NAME%-%LANG%-image {
      margin-left: -250px;
  }
}
}

@media not all and (min-resolution:.001dpcm) { 
  @media {
      #%PAGE_NAME%-%LANG% { 
          background-image: url("%JPG%");
      }
  }
}
</style>`;

export var GENERATED_CODE_GAME_WO = `<div class="slider_bg" id="%PAGE_NAME%-%LANG%" data-url="/%LANG%/games/%PAGE_NAME%.html" data-url-as="/%LANG%/games/%PAGE_NAME%.html">
  <div class="container" style="display: flex; height:100%; width: 100%"> 
    <img id="%PAGE_NAME%-%LANG%-image" src="%FOREGROUND_URL%" alt="%TAG%" style="max-width: fit-content; max-height: 100%; justify-content: flex-end; align-self: flex-end; object-fit: scale-down; object-position: bottom;">
  </div>
</div>

<style>
#%PAGE_NAME%-%LANG% {
  background-image: url("%WEBP%");
}

@media only screen and (max-width: 1120px) {
  #%PAGE_NAME%-%LANG% {
    background-size: 100% 101%!important;
    background-position: left bottom!important;
  }
}
  
@-moz-document url-prefix() {
  @media only screen and (min-width: 1024px) {
    #%PAGE_NAME%-%LANG%-image {
        margin-left: -250px;
    }
  }
}

@media not all and (min-resolution:.001dpcm) { 
    @media {
        #%PAGE_NAME%-%LANG% { 
            background-image: url("%JPG%");
        }
    }
}
</style>`;

export var GENERATED_CODE_MX = `
[
    {
        "title": "%TITLE%",
        "content": "%CONTENT%",
        "link": "/%LANG%/promotions/%LINK%",
        "label": "%LABEL%",
        "background": "%BACKGROUND_JPG%",
        "backgroundWEBP": "%BACKGROUND_WEBP%",
        "character": "%CHARACTER_PNG%",
        "characterWEBP": "%CHARACTER_WEBP%"
    }
]
`;

export var GENERATED_BANNER_CODE_MX = `
<li class="slide" style="position: absolute; display: block; z-index: -2; min-height: 100%; opacity: 0; inset: 0px; transition-timing-function: ease-in-out; transition-duration: 350ms;"><div class="slide-child slide0"><div class="background" style="background-image: url('%BACKGROUND_WEBP%';);"></div><div class="overlay"></div><div class="title">Offre de bienvenue</div><div class="content"><p>Profitez d’un bonus sur vos deux premiers dépôts !<br>Aucune condition de mise.</p><p><strong><span class="orange">100 %</span> jusqu’à 50 € | <span class="pink">50%</span> jusqu’à 50 € | <span class="blue"> 225</span> Free Spins</strong></p></div><button class="primary-btn large">J'en profite</button><div class="container-character"><img src="https://static.madnix.com/slider/2022/welcome-offer/welcome-offer_fg.webp" alt="Offre de bienvenue" class="perso"></div></div></li>
<div id="slide-child">
    <div class="background" style="background-image: url('%BACKGROUND_WEBP%');"></div>
    <div class="overlay"></div>
    <div class="title">%TITLE%</div>
    <div class="content">%CONTENT%</div>
    <button class="primary-btn large">%LABEL%</button>
    <div class="container-character">
        <img src="%CHARACTER_WEBP%" alt="Offre de bienvenue" class="perso">
    </div>
</div>
</li>
`;

export var GENERATED_CODE_WS = `
[
    {
        "title": "%TITLE%",
        "sub_title": "%SUB_TITLE%",
        "display_block":
           [%APPEARANCE%],
        "link": "/%LANG%%LINK%",
        "label": "%LABEL%",
        "background": "%BACKGROUND_JPG%",
        "backgroundWEBP": "%BACKGROUND_WEBP%"
    }
]
`

export var GENERATED_BANNER_CODE_WS = `
<li class="slide selected" id="ws-banner">
    <div class="slide-child slide0" style="background-image: url('%BACKGROUND_WEBP%');">
        <div class="content">
           <div class="title">%TITLE%</div>
           <div class="sub-title">%SUB_TITLE%</div><span class="btn btn-submit btn-superlarge">%LABEL%</span></div>
    </div>
</li>
`
