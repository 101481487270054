import WinouiSettings from "./brandSettings/WinouiSettings";
import WinouiLowSettings from "./brandSettings/WinouiLowSettings";
import MadnixSettings from "./brandSettings/MadnixSettings";
import WildSultanSettings from "./brandSettings/WildSultanSettings";
import PrevButton from "../common/PrevButton";
import NextButton from "../common/NextButton";
import { useState } from "react";

const SettingsStage = ({ selectedBrand, currentStage, handleNextStep, setGeneratedCodeEn, setGeneratedCodeFr }) => {
    const [isStepValid, setIsStepValid] = useState(false);
    const showCurrentStage = currentStage === 2 ? "d-flex" : "d-none";

    const handleStepValid = (value) => {
        setIsStepValid(value);
    }

    const settings = [
        {
            brand: "WinOui",
            page: <WinouiSettings
                setGeneratedCodeEn={setGeneratedCodeEn}
                setGeneratedCodeFr={setGeneratedCodeFr}
                setIsStepValid={handleStepValid}/>
        },
        {
            brand: "WinOuiLow",
            page: <WinouiLowSettings
                setGeneratedCodeEn={setGeneratedCodeEn}
                setGeneratedCodeFr={setGeneratedCodeFr}
                setIsStepValid={handleStepValid}/>
        },
        {
            brand: "MadNix",
            page: <MadnixSettings
                setGeneratedCodeEn={setGeneratedCodeEn}
                setGeneratedCodeFr={setGeneratedCodeFr}
                setIsStepValid={handleStepValid}/>
        },
        {
            brand: "WildSultan",
            page: <WildSultanSettings
                setGeneratedCodeEn={setGeneratedCodeEn}
                setGeneratedCodeFr={setGeneratedCodeFr}
                setIsStepValid={handleStepValid}/>
        }
    ];

    const renderSettings = settings.find(({ brand }) => brand === selectedBrand);

    return (
        <ul
            id="gform_fields_2"
            className={`gform_fields top_label form_sublabel_below description_below ${showCurrentStage} flex-column promo-settings`}>
            <li id="field_3"
                className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
                {renderSettings && renderSettings.page}
            </li>
            <li className="next-button col-3">
                <PrevButton
                    prevStep={1}
                    handleNextStep={handleNextStep}
                />
                <NextButton
                    isStepValid={isStepValid}
                    nextStep={3}
                    handleNextStep={handleNextStep}
                    label={"Submit"}
                    type="submit"
                />
            </li>
        </ul>
    )
}

export default SettingsStage;
