import { Fragment, useEffect, useState } from "react";
import wsLogo from "../../../img/wildsultan_logo.png";
import InputField from "../holders/InputField";
import imageWS from "../../../img/previews/ws/image-png-ws.jpg";
import titleWS from "../../../img/previews/ws/title-ws.jpg";
import subTitleWS from "../../../img/previews/ws/subtitle-ws.jpg";
import { WS_PROMO_CODE } from "../../../constants/promotionGenerator/generatedCode";
import { formattedDate } from "../../../constants/promotionGenerator/datas";

const WildSultanSettings = ({ setGeneratedCodeEn, setGeneratedCodeFr, setIsStepValid }) => {
    const [dateFrom, setDateFrom] = useState(formattedDate);
    const [titleEn, setTitleEn] = useState("");
    const [subTitleEn, setSubTitleEn] = useState("");
    const [imagePngEn, setImagePngEn] = useState("");
    const [backgroundPngEn, setBackgroundPngEn] = useState("");
    const [imagePngFr, setImagePngFr] = useState("");
    const [backgroundPngFr, setBackgroundPngFr] = useState("");
    const [dateTo, setDateTo] = useState(formattedDate);
    const [titleFr, setTitleFr] = useState("");
    const [subTitleFr, setSubTitleFr] = useState("");
    const [imageWebpEn, setImageWebpEn] = useState("");
    const [backgroundWebpEn, setBackgroundWebpEn] = useState("");
    const [imageWebpFr, setImageWebpFr] = useState("");
    const [backgroundWebpFr, setBackgroundWebpFr] = useState("");
    const [slug, setSlug] = useState("");

    const replaceVars = (codeTemplate, dateFrom, dateTo, subTitle, imgPng, backgroundPng, title, imageWebp, backgroundWebp, slug) =>
        codeTemplate
            .replace("%DATE_FROM%", dateFrom)
            .replace("%DATE_TO%", dateTo)
            .replace("%SUB_TITLE%", subTitle)
            .replace("%IMG_PNG%", imgPng)
            .replace("%BG_PNG%", backgroundPng)
            .replace("%TITLE%", title)
            .replace("%IMG_WEBP%", imageWebp)
            .replace("%BG_WEBP%", backgroundWebp)
            .replace("%SLUG%", slug);

    const resultCode = (codeTemplate, lang) => {
        return lang === "en" ?
            replaceVars(codeTemplate, dateFrom, dateTo, subTitleEn, imagePngEn, backgroundPngEn, titleEn,
                imageWebpEn, backgroundWebpEn, slug) :
            replaceVars(codeTemplate, dateFrom, dateTo, subTitleFr, imagePngFr, backgroundPngFr, titleFr,
                imageWebpFr, backgroundWebpFr, slug);
    };

    const checkValidity = () => {
        setIsStepValid(dateFrom && dateTo && subTitleEn && imagePngEn && backgroundPngEn && imagePngFr && backgroundPngFr
            && titleFr && subTitleFr && imageWebpEn && backgroundWebpEn && imageWebpFr && backgroundWebpFr && slug);
    };

    useEffect(() => {
        setGeneratedCodeEn(resultCode(WS_PROMO_CODE, "en"));
        setGeneratedCodeFr(resultCode(WS_PROMO_CODE, "fr"));
        checkValidity();
    });

    return (
        <Fragment>
            <label className="gfield_label"><img src={wsLogo} alt="WO logo" className="logo"/> Main information</label>
            <div className="col-12 row ws">
                <div className="col-6">
                    <InputField label="Date from " description="(YYYY/MM/DD)" setValue={setDateFrom} value={dateFrom}
                                placeholder="01/12/2023" id="dateFrom" tabIndex={1} helperImage={false}/>
                    <InputField label="Title " description="(en)" setValue={setTitleEn} value={titleEn}
                                placeholder="Title of promotion..." id="titleEn" tabIndex={3} previewHover={titleWS}/>
                    <InputField label="Subtitle " description="(en)" setValue={setSubTitleEn} value={subTitleEn}
                                placeholder="Subtitle of promotion..." id="subTitleEn" tabIndex={5}
                                previewHover={subTitleWS}/>
                    <InputField label="Image " description="(en png)" setValue={setImagePngEn} value={imagePngEn}
                                id="imagePng" tabIndex={7} previewHover={imageWS}/>
                    <InputField label="Image " description="(en webp)" setValue={setImageWebpEn} value={imageWebpEn}
                                id="imageWebp" tabIndex={9} previewHover={imageWS}/>
                    <InputField label="Background " description="(en png)" setValue={setBackgroundPngEn}
                                value={backgroundPngEn} id="backgroundPng" tabIndex={11} previewHover={imageWS}/>
                    <InputField label="Background " description="(en webp)" setValue={setBackgroundWebpEn}
                                value={backgroundWebpEn} id="backgroundWebp" tabIndex={13} previewHover={imageWS}/>
                    <InputField label="Slug" setValue={setSlug} value={slug} placeholder="Slug of promotion..."
                                id="slug" tabIndex={15} helperImage={false}/>
                </div>
                <div className="col-6">
                    <InputField label="Date to " description="(YYYY/MM/DD)" setValue={setDateTo} value={dateTo}
                                placeholder="2023/12/01" id="dateTo" tabIndex={2} helperImage={false}/>
                    <InputField label="Title " description="(fr)" setValue={setTitleFr} value={titleFr}
                                placeholder="Title of promotion..." id="titleFr" tabIndex={4} previewHover={titleWS}/>
                    <InputField label="Subtitle " description="(fr)" setValue={setSubTitleFr} value={subTitleFr}
                                placeholder="Subtitle of promotion..." id="subTitleFr" tabIndex={6}
                                previewHover={subTitleWS}/>
                    <InputField label="Image " description="(fr png)" setValue={setImagePngFr} value={imagePngFr}
                                id="imagePng" tabIndex={8} previewHover={imageWS}/>
                    <InputField label="Image " description="(fr webp)" setValue={setImageWebpFr} value={imageWebpFr}
                                id="imageWebp" tabIndex={10} previewHover={imageWS}/>
                    <InputField label="Background " description="(fr png)" setValue={setBackgroundPngFr}
                                value={backgroundPngFr} id="backgroundPng" tabIndex={12} previewHover={imageWS}/>
                    <InputField label="Background " description="(fr webp)" setValue={setBackgroundWebpFr}
                                value={backgroundWebpFr} id="backgroundWebp" tabIndex={14} previewHover={imageWS}/>
                </div>
            </div>
        </Fragment>
    )
}

export default WildSultanSettings;
