const InputPurpose = ({ index, name, handleChange }) => {

    return (
        <li className={`gchoice_2_${index - 1} col-3`}>
            <input
                name="input_35"
                type="radio"
                value={name}
                id={`choice_2_${index - 1}`}
                tabIndex={index}
                formvaluetype="purpose"
                onChange={handleChange}
            />
            <label
                htmlFor={`choice_2_${index - 1}`}
                id={`label_2_${index}`}
                className="d-flex"
            >
                <div className="logo flex-center disabled">
                    <span>{name.charAt(0)}</span>
                </div>
                <div className="label-description d-flex flex-column justify-content-center align-items-baseline">
                    <span className="label-title">{name}</span>
                </div>
            </label>
        </li>
    );
}

export default InputPurpose;
