import { useState } from "react";
import "./style/App.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/common/Header";
import BannerStage from "./components/bannerGenerator/BannerStage";
import PromoStage from "./components/promotionGenerator/PromoStage";
import SwitchGenerator from "./components/common/SwitchGenerator";

const App = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [stepValidated, setStepValidated] = useState(0);
    const [currentGenerator, setCurrentGenerator] = useState("bannerGenerator");

    const handleUpdate = (newValue) => {
        setCurrentGenerator(newValue);
        setCurrentStep(1);
        setStepValidated(0);
    };

    const handleNextStep = (nextStep) => {
        setCurrentStep(nextStep);
        switch (nextStep) {
            case 2:
                if (stepValidated < 1) {
                    setStepValidated(1);
                }
                break;
            case 3:
                if (stepValidated < 3) {
                    setStepValidated(3);
                }
                break;
            case "step2isNotValid":
                setStepValidated(1);
                setCurrentStep(2);
                break;
            case "step1changed":
                setStepValidated(0);
                setCurrentStep(1);
                break;
            case "step2changed":
                setStepValidated(1);
                setCurrentStep(2);
                break;
            default:
                break;
        }
    }

    return (
        <div className="App">
            <Header currentGenerator={currentGenerator}/>
            <main>
                <div className="post d-flex flex-column" id="kt_post">
                    <div id="kt_content_container">
                        <div className="stepper stepper-pills stepper-row d-flex justify-content-space-between"
                             id="kt_create_account_stepper">
                            <SwitchGenerator onUpdate={handleUpdate}
                                             currentGenerator={currentGenerator}/>
                            <div className="main-block">
                                {currentGenerator === "bannerGenerator" &&
                                <BannerStage currentGenerator={currentGenerator}
                                             handleNextStep={handleNextStep}
                                             currentStage={currentStep}
                                             currentStepPanel={currentStep}
                                             stepValidated={stepValidated}/>}
                                {currentGenerator === "promoGenerator" &&
                                <PromoStage currentGenerator={currentGenerator}
                                            handleNextStep={handleNextStep}
                                            currentStage={currentStep}
                                            currentStepPanel={currentStep}
                                            stepValidated={stepValidated}
                                            onUpdate={handleUpdate}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );

}

export default App;
