import { useState, useEffect } from "react";
import SelectLang from "../common/SelectLang";
import BannerPreview from "./BannerPreview";
import CodePreview from "../common/CodePreview";
import NextButton from "../common/NextButton";
import PrevButton from "../common/PrevButton";

const ResultView = (props, isStepValid) => {

    const [currentLangCodePreview, setCurrentLangCodePreview] = useState("en");
    const [resultBannerPreviewByLang, setResultBannerPreviewByLang] = useState("");
    const [resultCodePreviewByLang, setResultCodePreviewByLang] = useState("");

    useEffect(() => {
        generateCodePreview(props.selectedBrand, "en");
        // eslint-disable-next-line
    }, []);

    const switchCodePreview = (e) => {
        let selectedBrand = props.selectedBrand;
        let lang = e.target.value.replace("lang-", "");
        setCurrentLangCodePreview(lang);
        generateCodePreview(selectedBrand, lang);
    }

    const generateCodePreview = (brand, language) => {
        if (brand === "WinOui") {
            let resultCode = props.resultCode
                .replaceAll("%LANG%", language)
                .replace("%FOREGROUND_URL%", props.foreground[0][language]);
            setResultBannerPreviewByLang(resultCode);
            setResultCodePreviewByLang(resultCode);
        } else if (brand === "MadNix") {
            const stringifyContent = (content) => {
                return content.replaceAll(/\n/g, "").replaceAll("    ", "")
            }

            const content = stringifyContent(props.content[0][language]);

            let resultCode = props.resultCode
                .replace("%CHARACTER_PNG%", props.characterPng[0][language])
                .replace("%CHARACTER_WEBP%", props.characterWebp[0][language])
                .replace("%TITLE%", props.title[0][language])
                .replace("%CONTENT%", content)
                .replace("%LINK%", props.link)
                .replace("%LANG%", language)
                .replace("%LABEL%", props.label[0][language]);
            let resultBannerCode = props.resultBannerCode
                .replace("%CHARACTER_WEBP%", props.characterWebp[0][language])
                .replace("%TITLE%", props.title[0][language])
                .replace("%CONTENT%", content)
                .replace("%LABEL%", props.label[0][language]);
            setResultCodePreviewByLang(resultCode);
            setResultBannerPreviewByLang(resultBannerCode);
        } else {
            let resultCode = props.resultCode
                .replace("%TITLE%", props.title[0][language])
                .replace("%SUB_TITLE%", props.subTitle[0][language])
                .replace("%LABEL%", props.label[0][language])
                .replace("%LANG%", language)
                .replace("%LINK%", props.link);
            let resultBannerCode = props.resultBannerCode
                .replace("%TITLE%", props.title[0][language])
                .replace("%SUB_TITLE%", props.subTitle[0][language])
                .replace("%LABEL%", props.label[0][language]);
            setResultCodePreviewByLang(resultCode);
            setResultBannerPreviewByLang(resultBannerCode);
        }
    }

    return (
        <ul
            id="gform_fields_3"
            className={`gform_fields top_label form_sublabel_below description_below ${props.isCurrent ? "d-flex" : "d-none"} flex-column`}
        >
            <li id="field_5"
                className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible language-select">
                <label className="gfield_label"><img src={props.selectedBrandLogo} alt="WO logo"
                                                     className="logo"/> {props.stageTitle} banner preview</label>
                <SelectLang
                    selectedBrand={props.selectedBrand}
                    pos={1}
                    current={currentLangCodePreview}
                    switchLang={switchCodePreview}
                />
            </li>
            <BannerPreview
                resultBannerCode={resultBannerPreviewByLang}
            />
            <li id="field_6"
                className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible language-select">
                <label className="gfield_label"><img src={props.selectedBrandLogo} alt="WO logo"
                                                     className="logo"/> {props.stageTitle} banner HTML code</label>
                <SelectLang
                    selectedBrand={props.selectedBrand}
                    pos={2}
                    current={currentLangCodePreview}
                    switchLang={switchCodePreview}
                />
            </li>
            <CodePreview
                resultCode={resultCodePreviewByLang}
            />
            <li className="next-button copy-button">
                <PrevButton
                    prevStep={2}
                    handleNextStep={props.handleNextStep}
                />
                <NextButton
                    currentGenerator={props.currentGenerator}
                    isStepValid={isStepValid}
                    nextStep={"done"}
                    handleNextStep={props.handleNextStep}
                    label={"Copy Code"}
                    type="button"
                />
            </li>
        </ul>
    )
}

export default ResultView;
