import { Fragment, useEffect, useState } from "react";
import woLogo from "../../../img/winoui_logo.png";
import WeekDaysHolder from "../holders/WeekDaysHolder";
import InputField from "../holders/InputField";
import { WO_PROMO_CODE_LP } from "../../../constants/promotionGenerator/generatedCode";
import imgLogo from "../../../img/image_logo.svg";
import nameWO from "../../../img/previews/wo-lp/name-wo-lp.jpg";
import subnameWO from "../../../img/previews/wo-lp/subname-wo-lp.jpg";
import textWO from "../../../img/previews/wo-lp/text-wo-lp.jpg";
import periodicityWO from "../../../img/previews/wo-lp/periodicity-wo-lp.jpg";
import timeWO from "../../../img/previews/wo-lp/time-wo-lp.jpg";
import activeImgWO from "../../../img/previews/wo-lp/image-active-wo-lp.jpg";
import thumbImgWO from "../../../img/previews/wo-lp/image-thumb-wo-lp.jpg";
import promoThumbImgWO from "../../../img/previews/wo-lp/image-promo-thumb-wo-lp.jpg";
import grayWO from "../../../img/previews/wo-lp/gray-color-image.jpg";

const WinouiLowSettings = ({ setGeneratedCodeEn, setGeneratedCodeFr, setIsStepValid }) => {
    const [nameEn, setNameEn] = useState("");
    const [subnameEn, setSubnameEn] = useState("");
    const [nameFr, setNameFr] = useState("");
    const [subnameFr, setSubnameFr] = useState("");
    const [weekDays, setWeekDays] = useState("");
    const [formattedWeekDaysEn, setFormattedWeekDaysEn] = useState("");
    const [formattedWeekDaysFr, setFormattedWeekDaysFr] = useState("");
    const [weekDaysText, setWeekDaysText] = useState("");
    const [periodicity, setPeriodicity] = useState("");
    const [timeValue, setTimeValue] = useState("");
    const [textEn, setTextEn] = useState("");
    const [textFr, setTextFr] = useState("");
    const [activePngEn, setActivePngEn] = useState("");
    const [activePngFr, setActivePngFr] = useState("");
    const [activeWebpEn, setActiveWebpEn] = useState("");
    const [activeWebpFr, setActiveWebpFr] = useState("");
    const [thumbPngEn, setThumbPngEn] = useState("");
    const [thumbPngFr, setThumbPngFr] = useState("");
    const [thumbWebpEn, setThumbWebpEn] = useState("");
    const [thumbWebpFr, setThumbWebpFr] = useState("");
    const [promoThumbPngEn, setPromoThumbPngEn] = useState("");
    const [promoThumbPngFr, setPromoThumbPngFr] = useState("");
    const [promoThumbWebpEn, setPromoThumbWebpEn] = useState("");
    const [promoThumbWebpFr, setPromoThumbWebpFr] = useState("");
    const [grayPng, setGrayPng] = useState("");
    const [grayWebp, setGrayWebp] = useState("");
    const [slug, setSlug] = useState("");

    const replaceVars = (codeTemplate, name, subname, weekDays, formattedWeekDays, periodicity, timeValue, text, activePng,
                         activeWebp, thumbPng, thumbWebp, promoThumbPng, promoThumbWebp, grayPng, grayWebp, slug) =>
        codeTemplate
            .replace("%NAME%", name)
            .replace("%SUBNAME%", subname)
            .replace("%WEEK_DAYS%", weekDays)
            .replace("%FORMATTED_WEEK_DAYS%", formattedWeekDays)
            .replace("%PERIODICITY%", periodicity)
            .replace("%TIME%", timeValue)
            .replace("%TEXT%", text)
            .replace("%ACTIVE_PNG%", activePng)
            .replace("%ACTIVE_WEBP%", activeWebp)
            .replace("%THUMB_PNG%", thumbPng)
            .replace("%THUMB_WEBP%", thumbWebp)
            .replace("%PROMO_THUMB_PNG%", promoThumbPng)
            .replace("%PROMO_THUMB_WEBP%", promoThumbPng)
            .replace("%GRAY_PNG%", grayPng)
            .replace("%GRAY_WEBP%", grayWebp)
            .replace("%SLUG%", slug);

    const resultCode = (codeTemplate, lang) => {
        return lang === "en" ?
            replaceVars(codeTemplate, nameEn, subnameEn, weekDays, formattedWeekDaysEn, periodicity, timeValue, textEn, activePngEn,
                activeWebpEn, thumbPngEn, thumbWebpEn, promoThumbPngEn, promoThumbWebpEn, grayPng, grayWebp, slug) :
            replaceVars(codeTemplate, nameFr, subnameFr, weekDays, formattedWeekDaysFr, periodicity, timeValue, textFr, activePngFr,
                activeWebpFr, thumbPngFr, thumbWebpFr, promoThumbPngFr, promoThumbWebpFr, grayPng, grayWebp, slug)
    };

    const checkValidity = () => {
        setIsStepValid(nameEn && nameFr && subnameEn && subnameFr && weekDays && periodicity && timeValue && textEn &&
            textFr && activePngEn && activePngFr && activeWebpEn && activeWebpFr && thumbPngEn && thumbPngFr &&
            thumbWebpEn && thumbWebpFr && promoThumbPngEn && promoThumbPngFr && promoThumbWebpEn && promoThumbWebpFr
            && grayPng && grayWebp && slug);
    };

    useEffect(() => {
        setGeneratedCodeEn(resultCode(WO_PROMO_CODE_LP, "en"));
        setGeneratedCodeFr(resultCode(WO_PROMO_CODE_LP, "fr"));
        checkValidity();
    });

    return (
        <Fragment>
            <label className="gfield_label"><img src={woLogo} alt="WO logo" className="logo"/> Main information</label>
            <div className="col-12 row wo-lp">
                <div className="col-6">
                    <InputField label="Name " description="(en)" setValue={setNameEn} value={nameEn}
                                placeholder="Name of promotion..." id="nameEn" tabIndex={1}
                                previewHover={nameWO}/>
                    <InputField label="Subname " description="(en)" setValue={setSubnameEn} value={subnameEn}
                                placeholder="Subname of promotion..." id="subnameEn" tabIndex={3}
                                previewHover={subnameWO}/>
                </div>
                <div className="col-6">
                    <InputField label="Name " description="(fr)" setValue={setNameFr} value={nameFr}
                                placeholder="Name of promotion..." id="nameFr" tabIndex={2} helperImage={true}
                                previewHover={nameWO}/>
                    <InputField label="Subname " description="(fr)" setValue={setSubnameFr} value={subnameFr}
                                placeholder="Subname of promotion..." id="subnameFr" tabIndex={4}
                                previewHover={subnameWO}/>
                </div>
                <WeekDaysHolder setWeekDays={setWeekDays} weekDays={weekDays}
                                setWeekDaysText={setWeekDaysText} weekDaysText={weekDaysText}
                                checked={false} col={6} setFormattedWeekDaysEn={setFormattedWeekDaysEn}
                                setFormattedWeekDaysFr={setFormattedWeekDaysFr}/>
            </div>
            <div className="col-12 row">
                <div className="col-6">
                    <InputField label="Periodicity" setValue={setPeriodicity} value={periodicity}
                                placeholder="PERIODICITY: <span>EVERY WEEK</span>" id="periodicity" tabIndex={5}
                                previewHover={periodicityWO}/>
                    <div className="form-group col-12 small-textarea">
                        <label htmlFor="textEn" className="input-title">Text <span>(en)</span>
                            <span className="helper-icon hoverImg">
                                <img src={imgLogo} alt="copy"/>
                                <div className="preview">
                                    <img src={textWO} alt="preview" className="preview-hover"/>
                                </div>
                            </span>
                        </label>
                        <textarea className="form-control" id="textEn" name="textEn" tabIndex={7} value={textEn}
                                  placeholder="Text of promotion..." onChange={(e) => setTextEn(e.target.value)}/>
                    </div>
                    <InputField label="Image active " description="(en png)" setValue={setActivePngEn}
                                value={activePngEn} id="activePngEn" tabIndex={9} previewHover={activeImgWO}/>
                    <InputField label="Image active " description="(en webp)" setValue={setActiveWebpEn}
                                value={activeWebpEn} id="activeWebpEn" tabIndex={11} previewHover={activeImgWO}/>
                    <InputField label="Image thumb " description="(en png)" setValue={setThumbPngEn} value={thumbPngEn}
                                id="thumbPngEn" tabIndex={13} previewHover={thumbImgWO}/>
                    <InputField label="Image thumb " description="(en webp)" setValue={setThumbWebpEn}
                                value={thumbWebpEn} id="thumbWebpEn" tabIndex={15} previewHover={thumbImgWO}/>
                    <InputField label="Image promo thumb " description="(en png)" setValue={setPromoThumbPngEn}
                                value={promoThumbPngEn} id="promoThumbPngEn" tabIndex={17}
                                previewHover={promoThumbImgWO}/>
                    <InputField label="Image promo thumb " description="(en webp)" setValue={setPromoThumbWebpEn}
                                value={promoThumbWebpEn} id="promoThumbWebpEn" tabIndex={19}
                                previewHover={promoThumbImgWO}/>
                    <InputField label="Gray color image near promotion description " description="(png)"
                                setValue={setGrayPng} value={grayPng} id="grayPng" tabIndex={21} previewHover={grayWO}/>
                    <InputField label="Slug" setValue={setSlug} value={slug} placeholder="Slug of promotion..."
                                id="slug" tabIndex={23} helperImage={false}/>
                </div>
                <div className="col-6">
                    <InputField label="Time" setValue={setTimeValue} value={timeValue}
                                placeholder="TIME: <span>THURSDAY 00 AM TO SUNDAY MIDNIGHT</span>" id="timeValue"
                                tabIndex={6} previewHover={timeWO}/>
                    <div className="form-group col-12 small-textarea">
                        <label htmlFor="textFr" className="input-title">Text <span>(fr)</span>
                            <span className="helper-icon hoverImg">
                                <img src={imgLogo} alt="copy"/>
                                <div className="preview">
                                    <img src={textWO} alt="preview" className="preview-hover"/>
                                </div>
                            </span>
                        </label>
                        <textarea className="form-control" id="textFr" name="textFr" tabIndex={8} value={textFr}
                                  placeholder="Text of promotion..." onChange={(e) => setTextFr(e.target.value)}/>
                    </div>
                    <InputField label="Image active " description="(fr png)" setValue={setActivePngFr}
                                value={activePngFr} id="activePngFr" tabIndex={10} previewHover={activeImgWO}/>
                    <InputField label="Image active " description="(fr webp)" setValue={setActiveWebpFr}
                                value={activeWebpFr} id="activeWebpFr" tabIndex={12} previewHover={activeImgWO}/>
                    <InputField label="Image thumb " description="(fr png)" setValue={setThumbPngFr} value={thumbPngFr}
                                id="thumbPngFr" tabIndex={14} previewHover={thumbImgWO}/>
                    <InputField label="Image thumb " description="(fr webp)" setValue={setThumbWebpFr}
                                value={thumbWebpFr} id="thumbWebpFr" tabIndex={16} previewHover={thumbImgWO}/>
                    <InputField label="Image promo thumb " description="(fr png)" setValue={setPromoThumbPngFr}
                                value={promoThumbPngFr} id="promoThumbPngFr" tabIndex={18}
                                previewHover={promoThumbImgWO}/>
                    <InputField label="Image promo thumb " description="(fr webp)" setValue={setPromoThumbWebpFr}
                                value={promoThumbWebpFr} id="promoThumbWebpFr" tabIndex={20}
                                previewHover={promoThumbImgWO}/>
                    <InputField label="Gray color image near promotion description " description="(webp)"
                                setValue={setGrayWebp} value={grayWebp} id="grayWebp" tabIndex={22}
                                previewHover={grayWO}/>
                </div>
            </div>
        </Fragment>
    )
}

export default WinouiLowSettings;
