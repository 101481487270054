import imgLogo from "../../../img/image_logo.svg";

const InputField = ({
                        label,
                        description = "",
                        setValue,
                        value,
                        placeholder = "Link with img...",
                        id,
                        tabIndex,
                        helperImage = true,
                        previewHover = "",
                        mobileImg = false
                    }) => {

    return (
        <div className="form-group col-12">
            <label htmlFor={id} className="input-title">
                {label}
                <span>{description}</span>
                <span className={`helper-icon hoverImg ${helperImage ? "d-inline" : "d-none"}`}>
                    <img src={imgLogo} alt="logo"/>
                    <div className={`preview ${mobileImg ? 'mobile-preview' : ''}`}>
                         <img src={previewHover} alt="preview" className="preview-hover"/>
                    </div>
                </span>
            </label>
            <input
                type="text"
                className="form-control"
                id={id}
                name={id}
                tabIndex={tabIndex}
                value={value}
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
                required={true}
            />
        </div>
    )
}

export default InputField;
