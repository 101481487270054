const StepPanelTitle = (props) => {
    const { step, index, current, handleNextStep, stepValidated } = props;
    return (
        <div
            className={
                `stepper-item step-${step.index} 
            ${current === index ? "current" : ""} 
            ${stepValidated >= index ? "completed" : ""}`
            }
            data-kt-stepper-element="nav"
            onClick={stepValidated >= index ? () => handleNextStep(index) : () => {
                return false
            }}
        >
            <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"/>
                <span className="stepper-number">{step.index}</span>
            </div>
            <div className="stepper-label">
                <h3 className="stepper-title">{step.name}</h3>
                <div className="stepper-desc">{step.subTitle}</div>
            </div>
        </div>
    );
}

export default StepPanelTitle;
