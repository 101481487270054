import { PROMO_BRANDS } from "../../constants/promotionGenerator/datas";
import InputBrand from "../common/InputBrand";
import NextButton from "../common/NextButton";
import { useState } from "react";

const BrandStage = ({ currentStage, handleBrand, selectedBrand, handleNextStep }) => {
    const [isStepValid, setIsStepValid] = useState(false);
    const brand = {brand: selectedBrand};

    const validateBrand = (brand) => {
        if (brand !== "") {
            setIsStepValid(true);
        }
    };

    const handleChange = (e) => {
        handleBrand(e.target.value);
        validateBrand(brand);
    };

    return (
        <ul
            id="gform_fields_1"
            className={`gform_fields top_label form_sublabel_below description_below ${currentStage === 1 ? "d-flex" : "d-none"} flex-column`}
        >
            <li id="field_1"
                className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
                <label className="gfield_label promotion">Choose your brand</label>
                <p className="promotion-comment">Choosing the Brand</p>
                <div className="ginput_container ginput_container_radio">
                    <ul className="gfield_radio d-flex flex-wrap col-12" id="input_1">
                        {PROMO_BRANDS.map(brand => (
                            <InputBrand
                                key={brand.index}
                                index={brand.index}
                                name={brand.name}
                                title={brand.title}
                                url={brand.url}
                                logo={brand.logo}
                                isEnable={brand.isEnable}
                                handleChange={handleChange}
                            />
                        ))}
                    </ul>
                </div>
            </li>
            <li className="next-button col-3">
                <NextButton
                    isStepValid={isStepValid}
                    nextStep={2}
                    handleNextStep={handleNextStep}
                    label={"Next"}
                    type="button"
                />
            </li>
        </ul>
    )
}

export default BrandStage;
