import { Fragment, useState } from "react";
import StepsPanel from "../common/StepsPanel";
import BrandStage from "./BrandStage";
import SettingsStage from "./SettingsStage";
import ResultStage from "./ResultStage";

const PromoStage = ({ currentGenerator, currentStepPanel, handleNextStep, stepValidated, currentStage, onUpdate }) => {
    const [selectedBrand, setSelectedBrand] = useState("");
    const [generatedCodeEn, setGeneratedCodeEn] = useState("");
    const [generatedCodeFr, setGeneratedCodeFr] = useState("");

    const handleGeneratedCodeEn = (newValue) => {
        setGeneratedCodeEn(newValue);
    };

    const handleGeneratedCodeFr = (newValue) => {
        setGeneratedCodeFr(newValue);
    };

    const handleBrand = (newValue) => {
        setSelectedBrand(newValue);
    };

    const handleSubmit =(e) => {
        e.preventDefault();
        handleNextStep(3);
    };

    return (
        <Fragment>
            <div className="steps-wrapper">
                <StepsPanel
                    currentGenerator={currentGenerator}
                    currentStepPanel={currentStepPanel}
                    handleNextStep={handleNextStep}
                    stepValidated={stepValidated}
                />
            </div>
            <div className="card choose d-flex justify-content-center align-items-center">
                <form
                    onSubmit={handleSubmit}
                    className="card-body form-check-label"
                    noValidate="novalidate"
                    id="kt_create_account_form"
                >
                    <div className="wrap">
                        <div className="gform_wrapper">
                            <BrandStage
                                currentStage={currentStage}
                                handleBrand={handleBrand}
                                selectedBrand={selectedBrand}
                                handleNextStep={handleNextStep}/>
                            <SettingsStage
                                selectedBrand={selectedBrand}
                                currentStage={currentStage}
                                handleNextStep={handleNextStep}
                                setGeneratedCodeEn={handleGeneratedCodeEn}
                                setGeneratedCodeFr={handleGeneratedCodeFr}/>
                            <ResultStage
                                selectedBrand={selectedBrand}
                                currentStage={currentStage}
                                handleNextStep={handleNextStep}
                                generatedCodeEn={generatedCodeEn}
                                generatedCodeFr={generatedCodeFr}
                                currentGenerator={currentGenerator}
                                onUpdate={onUpdate}/>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default PromoStage;
