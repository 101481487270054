export var templates = [
    //1 template
    `<p>
    <strong>
        <span class="orange">€70 000 guaranteed</span> | 
        <span class="orange">09/06/2022 - 20/06/2022</span>
    </strong><br>
</p>
<p>Go on vacantion offered by Pr.NIX and Yggdrasil</p>`,
    //2 template
    `<p>Enter tha Americans valleys and fight with<br></p>
<p>
    <strong>
        <span class="orange">Bison Battle</span>
    </strong>
</p>`,
    //3 template
    `<p>Claim a 
    <strong>
        <span class="orange">100 %</span> bonus up to €50 + 
        <span class="blue">50</span> Free Spins
    </strong>
</p>
<p>No wagering conditions.<br>
Get started now.</p>`,
    //4 template
    `<ul>
     <li>No wagering conditions</li>
     <li>Withdrawals in less than 24hours</li>
     <li>Withdrawals 7D/7</li>
</ul>`
];
