import { useState } from "react";
import NextButton from "../common/NextButton";
import InputBrand from "../common/InputBrand";
import InputPurpose from "./InputPurpose";
import { BANNER_BRANDS, PURPOSES } from "../../constants/bannerGenerator/datas";

const BrandView = (props) => {
    const [brand, setBrand] = useState("");
    const [purpose, setPurpose] = useState("");
    const [isStepValid, setIsStepValid] = useState(false);

    const handleSelectedBrand = (e) => {
        let updatedPurpose = e.target.value === "WinOui" ? "" : purpose;
        if (e.target.className !== "disabled") {
            setBrand(e.target.value);
            props.handleSelectedBrand(e.target.value);
        } else {
            setBrand("");
        }
        checkStepValidity(e.target.value, updatedPurpose);
    }

    const handleSelectedPurpose = (e) => {
        setPurpose(e.target.id);
        props.handleSelectedPurpose(e.target.id, e.target.value);
        checkStepValidity(brand, e.target.id);
    }

    const handlers = {
        brand: handleSelectedBrand,
        purpose: handleSelectedPurpose
    }

    const handleChange = (e) => {
        props.handleNextStep("step1changed");
        const handler = handlers[e.target.attributes.formvaluetype.value];
        handler && handler(e);
    }

    const checkStepValidity = (checkBrand, checkPurpose) => {
        if ((checkBrand === "WinOui" && checkPurpose !== "") || (checkBrand !== "WinOui" && checkBrand !== "")) {
            setIsStepValid(true);
        } else {
            setIsStepValid(false);
        }
    }

    return (
        <ul
            id="gform_fields_1"
            className={`gform_fields top_label form_sublabel_below description_below ${props.isCurrent ? "d-flex" : "d-none"} flex-column`}
        >
            <li id="field_1"
                className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
                <label className="gfield_label">Choose casino brand</label>
                <div className="ginput_container ginput_container_radio">
                    <ul className="gfield_radio d-flex col-12" id="input_1">
                        {BANNER_BRANDS.map(brand => (
                            <InputBrand
                                key={brand.index}
                                index={brand.index}
                                name={brand.name}
                                title={brand.title}
                                url={brand.url}
                                logo={brand.logo}
                                isEnable={brand.isEnable}
                                handleChange={handleChange}
                            />
                        ))}
                    </ul>
                </div>
            </li>
            {brand === "WinOui" &&
            <li id="field_2"
                className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
                <label className="gfield_label">Set banner purpose</label>
                <div className="ginput_container ginput_container_radio">
                    <ul className="gfield_radio d-flex col-11" id="input_2">
                        {PURPOSES.map(purpose => (
                            <InputPurpose
                                key={purpose.index}
                                index={purpose.index}
                                name={purpose.name}
                                handleChange={handleChange}
                            />
                        ))}
                    </ul>
                </div>
            </li>
            }
            <li className="next-button col-3">
                <NextButton
                    isStepValid={isStepValid}
                    nextStep={2}
                    handleNextStep={props.handleNextStep}
                    label={"Next"}
                    type="button"
                />
            </li>
        </ul>
    );
}

export default BrandView;
