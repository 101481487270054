import { Fragment, useEffect, useState } from "react";
import woLogo from "../../../img/winoui_logo.png";
import InputField from "../holders/InputField";
import imgLogo from "../../../img/image_logo.svg";
import imageWO from "../../../img/previews/wo/image-wo.jpg";
import nameWO from "../../../img/previews/wo/name-wo.jpg";
import textWO from "../../../img/previews/wo/text-wo.jpg";
import { WO_PROMO_CODE } from "../../../constants/promotionGenerator/generatedCode";
import { formattedDate } from "../../../constants/promotionGenerator/datas";

const WinouiSettings = ({ setGeneratedCodeEn, setGeneratedCodeFr, setIsStepValid }) => {
    const [dateFrom, setDateFrom] = useState(formattedDate);
    const [pngEn, setPngEn] = useState("");
    const [webpEn, setWebpEn] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [textEn, setTextEn] = useState("");
    const [dateTo, setDateTo] = useState(formattedDate);
    const [pngFr, setPngFr] = useState("");
    const [webpFr, setWebpFr] = useState("");
    const [nameFr, setNameFr] = useState("");
    const [textFr, setTextFr] = useState("");
    const [slug, setSlug] = useState("");

    const replaceVars = (codeTemplate, dateFrom, dateTo, png, webp, name, text, slug) =>
        codeTemplate
            .replace("%DATE_FROM%", dateFrom)
            .replace("%DATE_TO%", dateTo)
            .replace("%IMG_PNG%", png)
            .replace("%IMG_WEBP%", webp)
            .replace("%NAME%", name)
            .replace("%TEXT%", text)
            .replace("%SLUG%", slug);

    const resultCode = (codeTemplate, lang) => {
        return lang === "en" ?
            replaceVars(codeTemplate, dateFrom, dateTo, pngEn, webpEn, nameEn, textEn, slug) :
            replaceVars(codeTemplate, dateFrom, dateTo, pngFr, webpFr, nameFr, textFr, slug);
    };

    const checkValidity = () => {
        setIsStepValid(dateFrom && dateTo && pngFr && webpFr && nameFr && textFr && pngEn && webpEn && nameEn && textEn && slug);
    };

    useEffect(() => {
        setGeneratedCodeEn(resultCode(WO_PROMO_CODE, "en"));
        setGeneratedCodeFr(resultCode(WO_PROMO_CODE, "fr"));
        checkValidity();
    });

    return (
        <Fragment>
            <label className="gfield_label"><img src={woLogo} alt="WO logo" className="logo"/> Main information</label>
            <div className="col-12 row wo">
                <div className="col-6">
                    <InputField label="Date from " description="(YYYY/MM/DD)" setValue={setDateFrom} value={dateFrom}
                                placeholder={formattedDate} id="dateFrom" tabIndex={1} helperImage={false}/>
                    <InputField label="Image " description="(en png)" setValue={setPngEn} value={pngEn}
                                id="pngEn" tabIndex={3} previewHover={imageWO}/>
                    <InputField label="Image " description="(webp png)" setValue={setWebpEn} value={webpEn}
                                id="webpEn" tabIndex={5} previewHover={imageWO}/>
                    <InputField label="Name " description="(en)" setValue={setNameEn} value={nameEn}
                                placeholder="Name of promotion..." id="nameEn" tabIndex={7} previewHover={nameWO}/>
                    <div className="form-group col-12 small-textarea">
                        <label htmlFor="textEn" className="input-title">Text <span>(en)</span>
                            <span className="helper-icon hoverImg">
                                <img src={imgLogo} alt="copy"/>
                                <div className="preview">
                                    <img src={textWO} alt="preview" className="preview-hover"/>
                                </div>
                            </span>
                        </label>
                        <textarea className="form-control" id="textEn" name="textEn" tabIndex={9} value={textEn}
                                  placeholder="Text of promotion..."
                                  onChange={(e) => setTextEn(e.target.value)}/>
                    </div>
                    <InputField label="Slug" setValue={setSlug} value={slug} placeholder="Slug of promotion..."
                                id="slug" tabIndex={11} helperImage={false}/>
                </div>
                <div className="col-6">
                    <InputField label="Date to " description="(YYYY/MM/DD)" setValue={setDateTo} value={dateTo}
                                placeholder={formattedDate} id="dateTo" tabIndex={2} helperImage={false}/>
                    <InputField label="Image " description="(fr png)" setValue={setPngFr} value={pngFr}
                                id="pngFr" tabIndex={4} previewHover={imageWO}/>
                    <InputField label="Image " description="(fr webp)" setValue={setWebpFr} value={webpFr}
                                id="webpFr" tabIndex={6} previewHover={imageWO}/>
                    <InputField label="Name " description="(fr)" setValue={setNameFr} value={nameFr}
                                placeholder="Name of promotion..." id="nameFr" tabIndex={8} previewHover={nameWO}/>
                    <div className="form-group col-12 small-textarea">
                        <label htmlFor="textFr" className="input-title">Text <span>(fr)</span>
                            <span className="helper-icon hoverImg">
                                <img src={imgLogo} alt="copy"/>
                                <div className="preview">
                                    <img src={textWO} alt="preview" className="preview-hover"/>
                                </div>
                            </span>
                        </label>
                        <textarea className="form-control" id="textFr" name="textFr" tabIndex={10} value={textFr}
                                  placeholder="Text of promotion..."
                                  onChange={(e) => setTextFr(e.target.value)}/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default WinouiSettings;
