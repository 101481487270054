import React, { Fragment } from "react";
import BrandStage from "./BrandStage";
import SettingsStage from "./SettingsStage";
import ResultStage from "./ResultStage";
import {
    GENERATED_CODE_GAME_WO,
    GENERATED_CODE_PROMO_WO,
    GENERATED_CODE_MX,
    GENERATED_BANNER_CODE_MX,
    GENERATED_CODE_WS,
    GENERATED_BANNER_CODE_WS
} from "../../constants/bannerGenerator/generatedCode";
import { BANNER_BRANDS } from "../../constants/bannerGenerator/datas";
import StepsPanel from "../common/StepsPanel";

class BannerStage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resultCode: "",
            resultBannerCode: "",
            foregroundEn: "",
            foregroundFr: "",
            selectedPurpose: "",
            selectedPurposeTitle: "",
            selectedBrand: "",
            selectedBrandLogo: "",
            characterPngEn: "",
            characterPngFr: "",
            characterWebpEn: "",
            characterWebpFr: "",
            titleEn: "",
            titleFr: "",
            contentEn: "",
            contentFr: "",
            link: "",
            labelEn: "",
            labelFr: "",
            titleDe: "",
            titleFi: "",
            subTitleEn: "",
            subTitleFr: "",
            subTitleDe: "",
            subTitleFi: "",
            labelDe: "",
            labelFi: "",
            appearance: [' "/"', ' "slot-machines"', ' "table-games"', ' "live-casino"', ' "other-games"']
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeAppearance = this.changeAppearance.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentStage !== prevProps.currentStage) {
            this.setState({ currentStep: this.props.currentStage });
        }
    }

    changeAppearance = event => {
        let newArray = [...this.state.appearance, event.target.value];
        if (this.state.appearance.includes(event.target.value)) {
            newArray = newArray.filter(value => value !== event.target.value);
        }
        this.setState(
            { appearance: newArray }
        )
    };

    handleSubmit(e) {
        e.preventDefault();
        const nextStepHandler = () => {
            this.props.handleNextStep(3);
            this.forceUpdate();
        }

        const resultCodeWO = (codeResult, setField) => {
            const promoWO = e.target.input_35.value;
            codeResult = codeResult.replaceAll("%JPG%", e.target.jpg.value)
                .replaceAll("%WEBP%", e.target.webp.value)
                .replaceAll("%TAG%", e.target.tag.value)
                .replaceAll("%PAGE_NAME%", e.target.pageName.value)

            this.setState({
                foregroundEn: e.target.en.value,
                foregroundFr: e.target.fr.value,
                resultCode: codeResult,
                resultBannerCode: codeResult,
            });

            setField = promoWO;
            const fieldName = setField === "Promotion" ? "resultCode" : "resultBannerCode";
            this.setState({ [fieldName]: codeResult });
            nextStepHandler();
        }

        const resultCodeMX = (codeResult, setField) => {
            codeResult = codeResult
                .replaceAll("%BACKGROUND_JPG%", e.target.backgroundJpg.value)
                .replaceAll("%BACKGROUND_WEBP%", e.target.backgroundWebp.value)

            this.setState({
                characterPngEn: e.target.characterPngEn.value,
                characterPngFr: e.target.characterPngFr.value,
                characterWebpEn: e.target.characterWebpEn.value,
                characterWebpFr: e.target.characterWebpFr.value,
                titleEn: e.target.titleEn.value,
                titleFr: e.target.titleFr.value,
                contentEn: e.target.contentEn.value,
                contentFr: e.target.contentFr.value,
                link: e.target.link.value,
                labelEn: e.target.labelEn.value,
                labelFr: e.target.labelFr.value,
            });

            const fieldName = setField === "code" ? "resultCode" : "resultBannerCode";
            this.setState({ [fieldName]: codeResult });
            nextStepHandler();
        }

        const resultCodeWS = (codeResult, setField) => {
            codeResult = codeResult
                .replaceAll("%BACKGROUND_JPG%", e.target.backgroundJpg.value)
                .replaceAll("%BACKGROUND_WEBP%", e.target.backgroundWebp.value)
                .replace("%APPEARANCE%", this.state.appearance)

            this.setState({
                titleEn: e.target.titleEn.value,
                titleFr: e.target.titleFr.value,
                titleDe: e.target.titleDe.value,
                titleFi: e.target.titleFi.value,
                subTitleEn: e.target.subTitleEn.value,
                subTitleFr: e.target.subTitleFr.value,
                subTitleDe: e.target.subTitleDe.value,
                subTitleFi: e.target.subTitleFi.value,
                labelEn: e.target.labelEn.value,
                labelFr: e.target.labelFr.value,
                labelDe: e.target.labelDe.value,
                labelFi: e.target.labelFi.value,
                link: e.target.link.value,
            });

            const fieldName = setField === "code" ? "resultCode" : "resultBannerCode";
            this.setState({ [fieldName]: codeResult });
            nextStepHandler();
        }

        if (e.target.input_34.value === "WinOui") {
            if (e.target.input_35.value === "Promotion") {
                resultCodeWO(GENERATED_CODE_PROMO_WO, "Promotion");
            } else {
                resultCodeWO(GENERATED_CODE_GAME_WO, "Game")
            }
        } else if (e.target.input_34.value === "MadNix") {
            resultCodeMX(GENERATED_CODE_MX, "code");
            resultCodeMX(GENERATED_BANNER_CODE_MX, "banner");
        } else {
            resultCodeWS(GENERATED_CODE_WS, "code");
            resultCodeWS(GENERATED_BANNER_CODE_WS, "banner");
        }
    }

    handleSelectedPurpose = (purpose, value) => {
        this.setState({ selectedPurpose: purpose });
        this.setState({ selectedPurposeTitle: value.toLowerCase() });
    }

    handleSelectedBrand = (brand) => {
        this.setState({ selectedBrand: brand });
        this.setState({ selectedBrandLogo: BANNER_BRANDS.find(({ name }) => name === brand).logo });
    }

    render() {
        return (
            <Fragment>
                <div className="steps-wrapper">
                    <StepsPanel
                        currentGenerator={this.props.currentGenerator}
                        currentStepPanel={this.props.currentStepPanel}
                        handleNextStep={this.props.handleNextStep}
                        stepValidated={this.props.stepValidated}
                    />
                </div>
                <div className="card choose d-flex justify-content-center align-items-center">
                    <form
                        onSubmit={this.handleSubmit}
                        className="card-body form-check-label"
                        noValidate="novalidate"
                        id="kt_create_account_form"
                    >
                        <div className="wrap">
                            <div className="gform_wrapper">
                                <BrandStage
                                    currentStage={this.props.currentStage}
                                    isCurrent={this.props.currentStage === 1}
                                    handleNextStep={this.props.handleNextStep}
                                    handleSelectedPurpose={this.handleSelectedPurpose}
                                    handleSelectedBrand={this.handleSelectedBrand}
                                />
                                <SettingsStage
                                    currentStage={this.props.currentStage}
                                    selectedBrand={this.state.selectedBrand}
                                    isCurrent={this.props.currentStage === 2}
                                    handleNextStep={this.props.handleNextStep}
                                    handleSubmit={this.handleSubmit}
                                    selectedPurpose={this.state.selectedPurpose}
                                    selectedBrandLogo={this.state.selectedBrandLogo}
                                    title={this.state.selectedBrand + " " + this.state.selectedPurposeTitle}
                                    appearance={this.state.appearance}
                                    changeAppearance={this.changeAppearance}
                                />
                                {this.props.currentStage === 3 &&
                                <ResultStage
                                    currentGenerator={this.props.currentGenerator}
                                    currentStage={this.props.currentStage}
                                    isCurrent={this.props.currentStage === 3}
                                    handleNextStep={this.props.handleNextStep}
                                    resultCode={this.state.resultCode}
                                    resultBannerCode={this.state.resultBannerCode}
                                    foreground={[{ en: this.state.foregroundEn, fr: this.state.foregroundFr }]}
                                    stageTitle={this.state.selectedBrand + " " + this.state.selectedPurposeTitle}
                                    selectedBrandLogo={this.state.selectedBrandLogo}
                                    selectedBrand={this.state.selectedBrand}
                                    characterPng={[{ en: this.state.characterPngEn, fr: this.state.characterPngFr }]}
                                    characterWebp={[{ en: this.state.characterWebpEn, fr: this.state.characterWebpFr }]}
                                    title={[{
                                        en: this.state.titleEn,
                                        fr: this.state.titleFr,
                                        de: this.state.titleDe,
                                        fi: this.state.titleFi
                                    }]}
                                    subTitle={[{
                                        en: this.state.subTitleEn,
                                        fr: this.state.subTitleFr,
                                        de: this.state.subTitleDe,
                                        fi: this.state.subTitleFi
                                    }]}
                                    content={[{ en: this.state.contentEn, fr: this.state.contentFr }]}
                                    link={this.state.link}
                                    label={[{
                                        en: this.state.labelEn,
                                        fr: this.state.labelFr,
                                        de: this.state.labelDe,
                                        fi: this.state.labelFi
                                    }]}
                                    appearance={this.state.appearance}
                                />}
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default BannerStage;
