import { Fragment, useEffect, useState } from "react";
import mxLogo from "../../../img/madnix_logo.png";
import InputField from "../holders/InputField";
import WeekDaysHolder from "../holders/WeekDaysHolder";
import SwitchTrueFalse from "../holders/SwitchTrueFalse";
import badgesMX from "../../../img/previews/mx/badges-mx.jpg";
import imageMX from "../../../img/previews/mx/image-mx.jpg";
import nameMX from "../../../img/previews/mx/name-mx.jpg";
import descriptionMX from "../../../img/previews/mx/short-description-mx.jpg";
import subtitleMX from "../../../img/previews/mx/subtitle-mx.jpg";
import titleMX from "../../../img/previews/mx/title-mx.jpg";
import providerMX from "../../../img/previews/mx/provider-logo-mx.jpg";
import { MX_PROMO_CODE } from "../../../constants/promotionGenerator/generatedCode";
import { formattedDate } from "../../../constants/promotionGenerator/datas";

const MadnixSettings = ({ setGeneratedCodeEn, setGeneratedCodeFr, setIsStepValid }) => {
    const [dateFrom, setDateFrom] = useState(formattedDate);
    const [nameEn, setNameEn] = useState("");
    const [titleEn, setTitleEn] = useState("");
    const [subTitleEn, setSubTitleEn] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [badgesEn, setBadgesEn] = useState("");
    const [providerLogo, setProviderLogo] = useState("");
    const [isDisplayOnLobby, setIsDisplayOnLobby] = useState("");
    const [isRecommended, setIsRecommended] = useState("");
    const [doctorJpeg, setDoctorJpeg] = useState("");
    const [dateTo, setDateTo] = useState(formattedDate);
    const [nameFr, setNameFr] = useState("");
    const [titleFr, setTitleFr] = useState("");
    const [subTitleFr, setSubTitleFr] = useState("");
    const [descriptionFr, setDescriptionFr] = useState("");
    const [badgesFr, setBadgesFr] = useState("");
    const [isVip, setIsVip] = useState("");
    const [weekDays, setWeekDays] = useState("");
    const [doctorWebp, setDoctorWebp] = useState("");
    const [slug, setSlug] = useState("");
    const badgesArrEn = badgesEn.split(", ");
    const badgesArrFr = badgesFr.split(", ");
    const checkIsDisplayed = isDisplayOnLobby === "displayed";
    const checkIsRecommended = isRecommended === "recommended";
    const checkIsVip = isVip === "isVip";

    const replaceVars = (codeTemplate, dateFrom, dateTo, name, title, subTitle, description, badges, providerLogo,
                         isVip, isDisplayOnLobby, weekDays, isRecommended, doctorJpeg, doctorWebp, slug) =>
        codeTemplate
            .replace("%DATE_FROM%", dateFrom)
            .replace("%DATE_TO%", dateTo)
            .replace("%NAME%", name)
            .replace("%TITLE%", title)
            .replace("%SUB_TITLE%", subTitle)
            .replace("%DESCRIPTION%", description)
            .replace("%BADGES%", JSON.stringify(badges))
            .replace("%PROVIDER%", providerLogo)
            .replace("%IS_VIP%", isVip)
            .replace("%IS_DISPLAYED%", isDisplayOnLobby)
            .replace("%WEEK_DAYS%", weekDays)
            .replace("%IS_RECOMMENDED%", isRecommended)
            .replace("%JPEG%", doctorJpeg)
            .replace("%WEBP%", doctorWebp)
            .replace("%SLUG%", slug);

    const resultCode = (codeTemplate, lang) => {
        return lang === "en" ?
            replaceVars(codeTemplate, dateFrom, dateTo, nameEn, titleEn, subTitleEn, descriptionEn, badgesArrEn,
                providerLogo, checkIsVip, checkIsDisplayed, weekDays, checkIsRecommended, doctorJpeg, doctorWebp, slug) :
            replaceVars(codeTemplate, dateFrom, dateTo, nameFr, titleFr, subTitleFr, descriptionFr, badgesArrFr,
                providerLogo, checkIsVip, checkIsDisplayed, weekDays, checkIsRecommended, doctorJpeg, doctorWebp, slug);
    };

    const checkValidity = () => {
        setIsStepValid(dateFrom && dateTo && nameEn && nameFr && nameFr && titleEn && subTitleEn && subTitleFr &&
            descriptionEn && descriptionFr && badgesEn && badgesFr && providerLogo && isVip && isDisplayOnLobby &&
            weekDays && isRecommended && doctorJpeg && doctorWebp && slug);
    };

    useEffect(() => {
        setGeneratedCodeEn(resultCode(MX_PROMO_CODE, "en"));
        setGeneratedCodeFr(resultCode(MX_PROMO_CODE, "fr"));
        checkValidity();
    });

    return (
        <Fragment>
            <label className="gfield_label"><img src={mxLogo} alt="MX logo" className="logo"/> Main information</label>
            <div className="col-12 row mx">
                <div className="col-6">
                    <InputField label="Date from " description="(YYYY/MM/DD)" setValue={setDateFrom} value={dateFrom}
                                placeholder={formattedDate} id="dateFrom" tabIndex={1} helperImage={false}/>
                    <InputField label="Name " description="(en)" setValue={setNameEn} value={nameEn}
                                placeholder="Name of promotion..." id="nameEn" tabIndex={3} previewHover={nameMX}/>
                    <InputField label="Title " description="(en)" setValue={setTitleEn} value={titleEn}
                                placeholder="Title of promotion..." id="titleEn" tabIndex={5} previewHover={titleMX}
                                mobileImg={true}/>
                    <InputField label="Subtitle " description="(en)" setValue={setSubTitleEn} value={subTitleEn}
                                placeholder="Subtitle of promotion..." id="subTitleEn" tabIndex={7}
                                previewHover={subtitleMX}/>
                    <InputField label="Short Description " description="(en)" setValue={setDescriptionEn}
                                value={descriptionEn} placeholder="Short description of promotion..."
                                id="descriptionEn" tabIndex={9} previewHover={descriptionMX}/>
                    <InputField label="Badges " description="(en)" setValue={setBadgesEn} value={badgesEn}
                                placeholder="Monday, Wednesday" id="badgesEn" tabIndex={11} previewHover={badgesMX}/>
                    <InputField label="Provider logo " setValue={setProviderLogo} value={providerLogo}
                                id="providerLogo" tabIndex={13} previewHover={providerMX} mobileImg={true}/>
                    <div className="form-group col-12 d-flex switch-inputs">
                        <SwitchTrueFalse title="Displayed On Lobby" trueValue="displayed" falseValue="notDisplayed"
                                         checked={isDisplayOnLobby} setChecked={setIsDisplayOnLobby}/>
                        <SwitchTrueFalse title="Recommended" trueValue="recommended" falseValue="notRecommended"
                                         checked={isRecommended} setChecked={setIsRecommended}/>
                    </div>
                    <InputField label="Image " description="(JPEG)" setValue={setDoctorJpeg} value={doctorJpeg}
                                id="doctorJpeg" tabIndex={15} previewHover={imageMX}/>
                    <InputField label="Slug" setValue={setSlug} value={slug} placeholder="Slug of promotion..."
                                id="slug" tabIndex={17} helperImage={false}/>
                </div>
                <div className="col-6">
                    <InputField label="Date to " description="(YYYY/MM/DD)" setValue={setDateTo} value={dateTo}
                                placeholder={formattedDate} id="dateTo" tabIndex={2} helperImage={false}/>
                    <InputField label="Name " description="(fr)" setValue={setNameFr} value={nameFr}
                                placeholder="Name of promotion..." id="nameFr" tabIndex={4} previewHover={nameMX}/>
                    <InputField label="Title " description="(fr)" setValue={setTitleFr} value={titleFr}
                                placeholder="Title of promotion..." id="titleFr" tabIndex={6} previewHover={titleMX}
                                mobileImg={true}/>
                    <InputField label="Subtitle " description="(fr)" setValue={setSubTitleFr} value={subTitleFr}
                                placeholder="Subtitle of promotion..." id="subTitleFr" tabIndex={8}
                                previewHover={subtitleMX}/>
                    <InputField label="Short Description " description="(fr)" setValue={setDescriptionFr}
                                value={descriptionFr} placeholder="Short description of promotion..."
                                id="descriptionFr" tabIndex={10} previewHover={descriptionMX}/>
                    <InputField label="Badges " description="(fr)" setValue={setBadgesFr} value={badgesFr}
                                placeholder="Monday, Wednesday" id="badgesFr" tabIndex={12} previewHover={badgesMX}/>
                    <div className="form-group col-12">
                        <SwitchTrueFalse title="Is Vip" trueValue="isVip" falseValue="isNotVip"
                                         checked={isVip} setChecked={setIsVip}/>
                    </div>
                    <WeekDaysHolder setWeekDays={setWeekDays} weekDays={weekDays} checked={false} col={12}/>
                    <InputField label="Image " description="(webp)" setValue={setDoctorWebp} value={doctorWebp}
                                id="doctorWebp" tabIndex={16} previewHover={imageMX}/>
                </div>
            </div>
        </Fragment>
    )
}

export default MadnixSettings;
