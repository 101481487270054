import { useState } from "react";
import wsLogo from "../../../img/wildsultan_logo.png"

const WildSultanSettings = (props) => {
    const [checked] = useState("checked");

    return (
        <li id="field_3"
            className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
            <label className="gfield_label"><img src={wsLogo} alt="WS logo"
                                                 className="logo"/> WildSultan banner
                setup</label>
            <div className="col-12 row">
                <div className="col-6">
                    <div className="form-group col-12">
                        <label htmlFor="backgroundJpg" className="input-title">Background
                            URL <span>(.jpg)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="backgroundJpg"
                            name="backgroundJpg"
                            tabIndex={1}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="titleEn" className="input-title">Title<span>(en)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleEn"
                            name="titleEn"
                            tabIndex={2}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="titleDe" className="input-title">Title<span>(de)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleDe"
                            name="titleDe"
                            tabIndex={3}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="subTitleEn" className="input-title">Sub title<span>(en)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="subTitleEn"
                            name="subTitleEn"
                            tabIndex={4}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="subTitleDe" className="input-title">Sub title<span>(de)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="subTitleDe"
                            name="subTitleDe"
                            tabIndex={5}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12 d-flex justify-content-between">
                        <div className="button-text__wrapper">
                            <label htmlFor="labelEn" className="input-title">Button text <span>(en)</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="labelEn"
                                name="labelEn"
                                tabIndex={6}
                                onChange={props.handleChange}
                            />
                        </div>
                        <div className="button-text__wrapper">
                            <label htmlFor="labelFr" className="input-title">Button text <span>(fr)</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="labelFr"
                                name="labelFr"
                                tabIndex={6}
                                onChange={props.handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="link" className="input-title">Page name / URL
                            (ex. <span>/promotions/wild-west</span> <i>*language added automatically</i>)</label>
                        <input
                            type="text"
                            className="form-control"
                            id="link"
                            name="link"
                            tabIndex={8}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group col-12">
                        <label htmlFor="backgroundWebp" className="input-title">Background
                            URL <span>(.webp)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="backgroundWebp"
                            name="backgroundWebp"
                            tabIndex={1}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="titleFr" className="input-title">Title<span>(fr)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFr"
                            name="titleFr"
                            tabIndex={2}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="titleFi" className="input-title">Title<span>(fi)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFi"
                            name="titleFi"
                            tabIndex={3}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="subTitleFr" className="input-title">Sub title<span>(fr)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="subTitleFr"
                            name="subTitleFr"
                            tabIndex={4}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="subTitleFi" className="input-title">Sub title<span>(fi)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="subTitleFi"
                            name="subTitleFi"
                            tabIndex={5}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12 d-flex justify-content-between">
                        <div className="button-text__wrapper">
                            <label htmlFor="labelDe" className="input-title">Button text <span>(de)</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="labelDe"
                                name="labelDe"
                                tabIndex={7}
                                onChange={props.handleChange}
                            />
                        </div>
                        <div className="button-text__wrapper">
                            <label htmlFor="labelFi" className="input-title">Button text <span>(fi)</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="labelFi"
                                name="labelFi"
                                tabIndex={7}
                                onChange={props.handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-group col-12">
                        <h3 className="input-title">Appearance</h3>
                        <div className="checkbox__wrapper">
                            <label htmlFor="homePage" className="input-title checkbox-item">
                                <input type="checkbox"
                                       defaultChecked={checked}
                                       onChange={props.changeAppearance}
                                       value={' "/"'}
                                       id={"homePage"}
                                />
                                <span className="checkmark"/>
                                Home Page
                            </label>
                            <label htmlFor="slotMachines" className="input-title checkbox-item">
                                <input type="checkbox"
                                       defaultChecked={checked}
                                       onChange={props.changeAppearance}
                                       value={' "slot-machines"'}
                                       id={"slotMachines"}
                                />
                                <span className="checkmark"/>
                                Slot Machines
                            </label>
                            <label htmlFor="tableGames" className="input-title checkbox-item">
                                <input type="checkbox"
                                       defaultChecked={checked}
                                       onChange={props.changeAppearance}
                                       value={' "table-games"'}
                                       id={"tableGames"}
                                />
                                <span className="checkmark"/>
                                Table Games
                            </label>
                            <label htmlFor="liveCasino" className="input-title checkbox-item">
                                <input type="checkbox"
                                       defaultChecked={checked}
                                       onChange={props.changeAppearance}
                                       value={' "live-casino"'}
                                       id={"liveCasino"}
                                />
                                <span className="checkmark"/>
                                Live Casino
                            </label>
                            <label htmlFor="otherGames" className="input-title checkbox-item">
                                <input type="checkbox"
                                       defaultChecked={checked}
                                       onChange={props.changeAppearance}
                                       value={' "other-games"'}
                                       id={"otherGames"}
                                />
                                <span className="checkmark"/>
                                Other Games
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default WildSultanSettings;
