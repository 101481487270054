import { useState } from "react";
import mxLogo from "../../../img/madnix_logo.png"
import lines1 from "../../../img/2-lines-1.jpg"
import lines2 from "../../../img/2-lines-2.jpg"
import lines3 from "../../../img/3-lines-1.jpg"
import linesList from "../../../img/3-lines-list.jpg"
import { templates } from "../../../constants/bannerGenerator/contentTempMX";

const MadnixSettings = (props) => {
    const [activeTemplates, setActiveTemplates] = useState(null);
    const [contentTemplatesEn, setContentTemplatesEn] = useState("");
    const [contentTemplatesFr, setContentTemplatesFr] = useState("");

    const toggleTemplates = (value) => {
        setActiveTemplates(value);
        setContentTemplatesEn(templates[value - 1]);
        setContentTemplatesFr(templates[value - 1]);
    };

    const textAreaChange = (event, lang) => {
        if (lang === "fr") {
            setContentTemplatesFr(event.target.value);
        } else {
            setContentTemplatesEn(event.target.value);
        }
        props.handleChange(event);
    };

    return (
        <li id="field_3"
            className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
            <label className="gfield_label"><img src={mxLogo} alt="MX logo"
                                                 className="logo"/> Madnix banner
                setup</label>
            <div className="col-12 row">
                <div className="col-6">
                    <div className="form-group col-12">
                        <label htmlFor="backgroundJpg" className="input-title">Background
                            URL <span>(.jpg)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="backgroundJpg"
                            name="backgroundJpg"
                            tabIndex={1}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="characterPngEn" className="input-title">Character
                            URL <span>(en .png)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="characterPngEn"
                            name="characterPngEn"
                            tabIndex={2}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="characterPngFr" className="input-title">Character
                            URL <span>(fr .png)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="characterPngFr"
                            name="characterPngFr"
                            tabIndex={3}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="titleEn" className="input-title">Title <span>(en)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleEn"
                            name="titleEn"
                            tabIndex={4}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12 content-block">
                        <p>Select text template</p>
                        <div className="lines-images">
                            <img
                                src={lines1}
                                alt="lines 1"
                                className={activeTemplates === 1 ? "active" : ""}
                                onClick={() => {
                                    toggleTemplates(1)
                                }}/>
                            <img src={lines2}
                                 alt="lines 2"
                                 className={activeTemplates === 2 ? "active" : ""}
                                 onClick={() => {
                                     toggleTemplates(2)
                                 }}/>
                        </div>
                        <label htmlFor="contentEn" className="input-title">Content <span>(en)</span></label>
                        <textarea
                            type="text"
                            className="form-control"
                            value={contentTemplatesEn}
                            id="contentEn"
                            name="contentEn"
                            tabIndex={5}
                            onChange={(e) => {
                                textAreaChange(e, "en")
                            }}
                        />
                        <div className="comment">Play with text color by changing span classes, you can use only
                            "orange", "blue", "pink" classes
                        </div>
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="labelEn" className="input-title">
                            Button text <span>(en)</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="labelEn"
                            name="labelEn"
                            tabIndex={7}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="link" className="input-title">
                            Page name<span>(url)</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="link"
                            name="link"
                            tabIndex={6}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group col-12">
                        <label htmlFor="backgroundWebp" className="input-title">Background
                            URL <span>(.webp)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="backgroundWebp"
                            name="backgroundWebp"
                            tabIndex={1}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="characterWebpEn" className="input-title">Character
                            URL <span>(en .webp)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="characterWebpEn"
                            name="characterWebpEn"
                            tabIndex={2}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="characterWebpFr" className="input-title">Character
                            URL <span>(fr .webp)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="characterWebpFr"
                            name="characterWebpFr"
                            tabIndex={3}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="titleFr" className="input-title">Title <span>(fr)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFr"
                            name="titleFr"
                            tabIndex={4}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12 content-block">
                        <p className="invisible">Select text template</p>
                        <div className="lines-images">
                            <img
                                src={lines3}
                                alt="lines 3"
                                className={activeTemplates === 3 ? "active" : ""}
                                onClick={() => {
                                    toggleTemplates(3);
                                }}
                            />
                            <img
                                src={linesList}
                                alt="lines list"
                                className={activeTemplates === 4 ? "active" : ""}
                                onClick={() => {
                                    toggleTemplates(4);
                                }}
                            />
                        </div>
                        <label htmlFor="contentFr" className="input-title">
                            Content <span>(fr)</span>
                        </label>
                        <textarea
                            type="text"
                            className="form-control"
                            value={contentTemplatesFr}
                            id="contentFr"
                            name="contentFr"
                            tabIndex={5}
                            onChange={(e) => {
                                textAreaChange(e, "fr");
                            }}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="labelFr" className="input-title">
                            Button text <span>(fr)</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="labelFr"
                            name="labelFr"
                            tabIndex={7}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
            </div>
        </li>
    )
}

export default MadnixSettings;
