const PrevButton = (props) => {
    return (
        <button
            type="button"
            className="btn back-btn"
            data-kt-stepper-action="back" id="back-btn-1"
            onClick={() => props.handleNextStep(props.prevStep)}
        >
            Back
        </button>
    )
}

export default PrevButton;
