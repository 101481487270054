export const WO_PROMO_CODE = `{
    "dateFrom": "%DATE_FROM%",
    "dateTo": "%DATE_TO%",
    "image": "%IMG_PNG%",
    "imageWEBP": "%IMG_WEBP%",
    "name": "%NAME%",
    "text": "%TEXT%",
    "slug": "%SLUG%"
}`;

export const WO_PROMO_CODE_LP = `{
    "name": "%NAME%",
    "subname": "%SUBNAME%",
    "image": "%ACTIVE_PNG%",
    "thumb": "%THUMB_PNG%",
    "thumbBig": "%PROMO_THUMB_PNG%",
    "decorationImage": "%GRAY_PNG%",
    "imageWEBP": "%ACTIVE_WEBP%",
    "thumbWEBP": "%THUMB_WEBP%",
    "thumbBigWEBP": "%PROMO_THUMB_WEBP%",
    "decorationImageWEBP": "%GRAY_WEBP%",
    "weekDayNumber": [%WEEK_DAYS%],
    "weekDaySmall": "%FORMATTED_WEEK_DAYS%",
    "text": "%TEXT%",
    "periodicity": "%PERIODICITY%",
    "time": "%TIME%",
    "slug": "%SLUG%"
}`;

export const MX_PROMO_CODE = `{
    "title": "%TITLE%",
    "name": "%NAME%",
    "slug": "%SLUG%",
    "date-from": "%DATE_FROM%",
    "date-to": "%DATE_TO%",
    "background": "%JPEG%",
    "backgroundWEBP": "%WEBP%",
    "displayedOnLobby": %IS_DISPLAYED%,
    "daysOfTheWeek": [%WEEK_DAYS%],
    "recommended": %IS_RECOMMENDED%,
    "subtitle": "%SUB_TITLE%",
    "shortDescription": "%DESCRIPTION%",
    "badges": %BADGES%,
    "providerLogo": "%PROVIDER%",
    "isVip": %IS_VIP%
}`;

export const WS_PROMO_CODE = `{
    "title": "%TITLE%",
    "subtitle": "%SUB_TITLE%",
    "slug": "%SLUG%",
    "dateFrom": "%DATE_FROM%",
    "dateTo": "%DATE_TO%",
    "background": "%IMG_PNG%",
    "backgroundWEBP": "%IMG_WEBP%",
    "bigBackground": "%BG_PNG%",
    "bigBackgroundWEBP": "%BG_WEBP%"
}`;
