const SwitchTrueFalse = ({ title, trueValue, falseValue, checked, setChecked }) => {
    const options = [{ value: trueValue, label: "True"}, { value: falseValue, label: "False"}];

    const onValueChange = (e) => {
        setChecked(e.target.value);
    };

    return (
        <div className="input-wrapper">
            <h5 className="input-title">{title}</h5>
            <div className="form__radio-wrapper switcher">
                {options.map(({ value, label }) => (
                    <div
                        className="form__radio-wrap"
                        key={value}
                    >
                        <input
                            className="j-language-radio"
                            type="radio"
                            value={value}
                            id={value}
                            checked={checked === value}
                            onChange={onValueChange}
                        />
                        <label htmlFor={value}>{label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SwitchTrueFalse;
