import { useState, useEffect } from "react";
import NextButton from "../common/NextButton";
import PrevButton from "../common/PrevButton";
import WinouiSettings from "./brandSettings/WinouiSettings";
import MadnixSettings from "./brandSettings/MadnixSettings";
import WildSultanSettings from "./brandSettings/WildSultanSettings";

const SettingsView = (props) => {
    const [state, setState] = useState({
        isStepValid: false,
        jpg: "",
        webp: "",
        en: "",
        fr: "",
        tag: "",
        pageName: "",
        backgroundJpg: "",
        backgroundWebp: "",
        titleEn: "",
        contentEn: "",
        labelEn: "",
        characterPngEn: "",
        characterPngFr: "",
        characterWebpEn: "",
        characterWebpFr: "",
        titleFr: "",
        contentFr: "",
        link: "",
        labelFr: "",
        titleDe: "",
        titleFi: "",
        subTitleEn: "",
        subTitleFr: "",
        subTitleDe: "",
        subTitleFi: "",
        labelDe: "",
        labelFi: "",
        appearance: ""
    });

    const changeHandler = (e) => {
        props.handleNextStep("step2changed");
        if (props.selectedBrand === "WinOui") {
            switch (e.target.id) {
                case "jpg":
                    setState({ ...state, jpg: e.target.value });
                    break;
                case "webp":
                    setState({ ...state, webp: e.target.value });
                    break;
                case "en":
                    setState({ ...state, en: e.target.value });
                    break;
                case "fr":
                    setState({ ...state, fr: e.target.value });
                    break;
                case "tag":
                    setState({ ...state, tag: e.target.value });
                    break;
                case "page-name":
                    setState({ ...state, pageName: e.target.value });
                    break;
                default:
                    return false;
            }
        } else if (props.selectedBrand === "MadNix") {
            switch (e.target.id) {
                case "backgroundJpg":
                    setState({ ...state, backgroundJpg: e.target.value });
                    break;
                case "backgroundWebp":
                    setState({ ...state, backgroundWebp: e.target.value });
                    break;
                case "titleEn":
                    setState({ ...state, titleEn: e.target.value });
                    break;
                case "contentEn":
                    setState({ ...state, contentEn: e.target.value });
                    break;
                case "labelEn":
                    setState({ ...state, labelEn: e.target.value });
                    break;
                case "characterPngEn":
                    setState({ ...state, characterPngEn: e.target.value });
                    break;
                case "characterPngFr":
                    setState({ ...state, characterPngFr: e.target.value });
                    break;
                case "characterWebpEn":
                    setState({ ...state, characterWebpEn: e.target.value });
                    break;
                case "characterWebpFr":
                    setState({ ...state, characterWebpFr: e.target.value });
                    break;
                case "titleFr":
                    setState({ ...state, titleFr: e.target.value });
                    break;
                case "contentFr":
                    setState({ ...state, contentFr: e.target.value });
                    break;
                case "link":
                    setState({ ...state, link: e.target.value });
                    break;
                case "labelFr":
                    setState({ ...state, labelFr: e.target.value });
                    break;
                default:
                    return false;
            }
        } else {
            switch (e.target.id) {
                case "backgroundJpg":
                    setState({ ...state, backgroundJpg: e.target.value });
                    break;
                case "backgroundWebp":
                    setState({ ...state, backgroundWebp: e.target.value });
                    break;
                case "titleEn":
                    setState({ ...state, titleEn: e.target.value });
                    break;
                case "titleFr":
                    setState({ ...state, titleFr: e.target.value });
                    break;
                case "titleDe":
                    setState({ ...state, titleDe: e.target.value });
                    break;
                case "titleFi":
                    setState({ ...state, titleFi: e.target.value });
                    break;
                case "subTitleEn":
                    setState({ ...state, subTitleEn: e.target.value });
                    break;
                case "subTitleFr":
                    setState({ ...state, subTitleFr: e.target.value });
                    break;
                case "subTitleDe":
                    setState({ ...state, subTitleDe: e.target.value });
                    break;
                case "subTitleFi":
                    setState({ ...state, subTitleFi: e.target.value });
                    break;
                case "labelEn":
                    setState({ ...state, labelEn: e.target.value });
                    break;
                case "labelFr":
                    setState({ ...state, labelFr: e.target.value });
                    break;
                case "labelDe":
                    setState({ ...state, labelDe: e.target.value });
                    break;
                case "labelFi":
                    setState({ ...state, labelFi: e.target.value });
                    break;
                case "link":
                    setState({ ...state, link: e.target.value });
                    break;
                case "appearance":
                    setState({ ...state, appearance: props.appearance });
                    break;
                default:
                    return false;
            }
        }
    }

    useEffect(() => {
        checkStepValidity();
        // eslint-disable-next-line
    }, [state]);

    const checkStepValidity = () => {
        let requiredFields;
        if (props.selectedBrand === "WinOui") {
            requiredFields = [
                state.jpg,
                state.webp,
                state.en,
                state.fr,
                state.tag,
                state.pageName
            ];
        } else if (props.selectedBrand === "MadNix") {
            requiredFields = [
                state.backgroundJpg,
                state.backgroundWebp,
                state.titleEn,
                state.contentEn,
                state.labelEn,
                state.characterPngEn,
                state.characterPngFr,
                state.characterWebpEn,
                state.characterWebpFr,
                state.titleFr,
                state.contentFr,
                state.link,
                state.labelFr
            ];
        } else {
            requiredFields = [
                state.backgroundJpg,
                state.backgroundWebp,
                state.titleEn,
                state.titleFr,
                state.titleDe,
                state.titleFi,
                state.subTitleEn,
                state.subTitleFr,
                state.subTitleDe,
                state.subTitleFi,
                state.labelEn,
                state.labelFr,
                state.labelDe,
                state.labelFi,
                state.link
            ];
        }

        let isStepValid = !requiredFields.includes("");

        if (isStepValid !== state.isStepValid) {
            if (isStepValid) {
                setState({ ...state, isStepValid: isStepValid });
            } else {
                props.handleNextStep("step2isNotValid");
                setState({ ...state, isStepValid: isStepValid });
            }
        }
    }

    return (
        <ul
            id="gform_fields_2"
            className={`gform_fields top_label form_sublabel_below description_below ${props.isCurrent ? "d-flex" : "d-none"} flex-column`}
        >
            {props.selectedBrand === "WinOui" ?
                <WinouiSettings
                    title={props.title}
                    selectedBrand={props.selectedBrand}
                    handleChange={changeHandler}
                    handleNextStep={props.handleNextStep}
                /> : props.selectedBrand === "MadNix" ?
                    <MadnixSettings
                        title={props.title}
                        selectedBrand={props.selectedBrand}
                        handleChange={changeHandler}
                        handleNextStep={props.handleNextStep}
                    /> :
                    <WildSultanSettings
                        title={props.title}
                        selectedBrand={props.selectedBrand}
                        handleChange={changeHandler}
                        handleNextStep={props.handleNextStep}
                        appearance={props.appearance}
                        changeAppearance={props.changeAppearance}
                    />
            }
            <li className="next-button col-3">
                <PrevButton
                    prevStep={1}
                    handleNextStep={props.handleNextStep}
                />
                <NextButton
                    isStepValid={state.isStepValid}
                    nextStep={3}
                    handleNextStep={props.handleNextStep}
                    label={"Generate code"}
                    type="submit"
                />
            </li>
        </ul>
    );
}

export default SettingsView;
