import { LANGS } from "../../constants/bannerGenerator/datas";

const SelectLang = (props) => {

    const onLangChange = (e) => {
        props.switchLang(e);
    }

    const availableLangs = LANGS.filter(item => item.brand.includes(props.selectedBrand));
    return (
        <div className="form__radio-wrapper">
            {availableLangs.map(lang => (
                <div
                    className="form__radio-wrap"
                    key={lang.index}
                >
                    <input
                        className="j-language-radio"
                        type="radio"
                        name={`languages${props.pos}`}
                        value={`lang-${lang.name.toLowerCase()}`}
                        id={`lang-${lang.name}-${props.pos}`}
                        checked={props.current === lang.name.toLowerCase()}
                        onChange={onLangChange}
                    />
                    <label htmlFor={`lang-${lang.name}-${props.pos}`}>{lang.name}</label>
                </div>
            ))}
        </div>
    );
}

export default SelectLang;
