import { Fragment, useEffect, useRef, useState } from "react";

const NextButton = ({ nextStep, label, isStepValid, type, handleNextStep, currentGenerator, onUpdate }) => {
    const [copySuccess, setCopySuccess] = useState("");
    const timerRef = useRef(null);

    useEffect(() => {
        return () => {
            clearTimeout(timerRef?.current);
        };
    }, []);

    const copyToClipboard = (e) => {
        clearTimeout(timerRef?.current);
        const element = document.querySelector(".generatedCode");
        element.select();
        document.execCommand("copy");
        e.target.focus();
        setCopySuccess("Copied!");
        timerRef.current = setTimeout(() => {
            setCopySuccess("");
        }, 2000);
    };

    const backToHome = () => {
        handleNextStep(1);
        onUpdate("bannerGenerator");
    }

    return (
        <Fragment>
            {nextStep === "done" && (
                <button
                    type="button"
                    className="btn btn-lg enable"
                    data-kt-stepper-action="next"
                    id="next-btn"
                    onClick={currentGenerator === "bannerGenerator" ? copyToClipboard : backToHome}>
                    {copySuccess === "" ? label : copySuccess}
                </button>
            )}
            {nextStep !== "done" && nextStep !== 3 && (
                <button
                    type={type}
                    className={`btn btn-lg ${isStepValid ? "enable" : "disabled"}`}
                    data-kt-stepper-action="next"
                    id="next-btn"
                    onClick={isStepValid ? () => handleNextStep(nextStep) : () => {
                        return false
                    }}
                >
                    {label}
                </button>
            )}
            {nextStep === 3 && (
                <input
                    type="submit"
                    value={label}
                    className={`btn btn-lg ${!isStepValid ? "disabled" : "enable"}`}
                />
            )}
        </Fragment>
    );
}

export default NextButton;
