import StepPanelTitle from "./StepPanelTitle";
import { BANNER_STEPS } from "../../constants/bannerGenerator/datas";
import { PROMO_STEPS } from "../../constants/promotionGenerator/datas";

const StepsPanel = ({ currentStepPanel, handleNextStep, stepValidated, currentGenerator }) => {

    const steps = currentGenerator === "bannerGenerator" ? BANNER_STEPS : PROMO_STEPS;

    return (
        <div className="card steps d-flex justify-content-center">
            <div className="card-body px-6 px-lg-12 px-xxl-15 py-20">
                <div className="stepper-nav" id="stepper-nav">
                    {steps.map(step => (
                        <StepPanelTitle
                            key={step.index}
                            index={step.index}
                            step={step}
                            current={currentStepPanel}
                            handleNextStep={handleNextStep}
                            stepValidated={stepValidated}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default StepsPanel;
