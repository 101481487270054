import woLogo from "../../../img/winoui_logo.png"

const WinouiSettings = (props) => {

    return (
        <li id="field_3"
            className="gfield button-select-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
            <label className="gfield_label"><img src={woLogo} alt="WO logo"
                                                 className="logo"/> {props.title} banner
                setup</label>
            <div className="col-12 row position-relative">
                <div className="col-6">
                    <div className="form-group col-12">
                        <label htmlFor="jpg" className="input-title">Background URL
                            <span>(.jpg)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="jpg"
                            name="jpg"
                            tabIndex={1}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="en" className="input-title">Foreground
                            URL <span>(en .png)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="en"
                            name="en"
                            tabIndex={3}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="tag" className="input-title">Alt tag (ex. promo name or game
                            name + game
                            provider)</label>
                        <input
                            type="text"
                            className="form-control"
                            id="tag"
                            name="tag"
                            tabIndex={4}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group col-12">
                        <label htmlFor="webp" className="input-title">Background URL
                            <span>(.webp)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="webp"
                            name="webp"
                            tabIndex={2}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="fr" className="input-title">Foreground URL <span>(fr.png)</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="fr"
                            name="fr"
                            tabIndex={3}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="page-name" className="input-title">Page name /
                            URL {props.selectedPurpose === "choice_2_1" && (
                                <span>(dont include .html extention)</span>)}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="page-name"
                            name="pageName"
                            tabIndex={5}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
            </div>
        </li>
    )
}

export default WinouiSettings;
