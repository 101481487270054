import logoSrc from "../../img/logo.png";

const Header = (props) => {

    return (
        <header>
            <div className="logo">
                <img src={logoSrc} alt="TLFE Logo"/>
            </div>
            <h2>{props.currentGenerator === "bannerGenerator" ? "Banner generator" : "Promo page generator"}</h2>
        </header>
    );
}

export default Header;
