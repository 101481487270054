import { useEffect } from "react";

const WeekDaysHolder = ({ setWeekDays, weekDays, checked, col, setFormattedWeekDaysEn, setFormattedWeekDaysFr }) => {

    const setWeekDay = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setWeekDays([...weekDays, value]);
        } else {
            setWeekDays(weekDays.filter((item) => item !== value));
        }
    }

    const daysOfWeekEnglish = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const daysOfWeekFrench = ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"];

    const numberToDayOfWeek = (number, language = "en") => {
        const daysOfWeek = language === "en" ? daysOfWeekEnglish : daysOfWeekFrench;
        return daysOfWeek[number % 7];
    }

    const formatValues = (values, language = "en") => {
        if (values) {
            const formattedValues = values.map(value => numberToDayOfWeek(value, language));
            return formattedValues.length === 1 ? formattedValues[0] : formattedValues.join("/");
        }
    }

   useEffect(() => {
       if (setFormattedWeekDaysEn && setFormattedWeekDaysFr) {
           setFormattedWeekDaysEn(formatValues(weekDays, "en"));
           setFormattedWeekDaysFr(formatValues(weekDays, "fr"));
       }
   });

    return (
        <div className={`form-group col-${col} week-day-inputs`}>
            <h3 className="input-title">Week Day Number</h3>
            <div className="checkbox__wrapper justify-content-start">
                <label htmlFor="monday" className="input-title checkbox-item">
                    <input type="checkbox"
                           defaultChecked={checked}
                           onChange={setWeekDay}
                           value={1}
                           id="monday"
                    />
                    <span className="checkmark"/>
                    Monday
                </label>
                <label htmlFor="tuesday" className="input-title checkbox-item">
                    <input type="checkbox"
                           defaultChecked={checked}
                           onChange={setWeekDay}
                           value={2}
                           id="tuesday"
                    />
                    <span className="checkmark"/>
                    Tuesday
                </label>
                <label htmlFor="wednesday" className="input-title checkbox-item">
                    <input type="checkbox"
                           defaultChecked={checked}
                           onChange={setWeekDay}
                           value={3}
                           id="wednesday"
                    />
                    <span className="checkmark"/>
                    Wednesday
                </label>
                <label htmlFor="thursday" className="input-title checkbox-item">
                    <input type="checkbox"
                           defaultChecked={checked}
                           onChange={setWeekDay}
                           value={4}
                           id="thursday"
                    />
                    <span className="checkmark"/>
                    Thursday
                </label>
                <label htmlFor="friday" className="input-title checkbox-item">
                    <input type="checkbox"
                           defaultChecked={checked}
                           onChange={setWeekDay}
                           value={5}
                           id="friday"
                    />
                    <span className="checkmark"/>
                    Friday
                </label>
                <label htmlFor="saturday" className="input-title checkbox-item">
                    <input type="checkbox"
                           defaultChecked={checked}
                           onChange={setWeekDay}
                           value={6}
                           id="saturday"
                    />
                    <span className="checkmark"/>
                    Saturday
                </label>
                <label htmlFor="sunday" className="input-title checkbox-item">
                    <input type="checkbox"
                           defaultChecked={checked}
                           onChange={setWeekDay}
                           value={0}
                           id="sunday"
                    />
                    <span className="checkmark"/>
                    Sunday
                </label>
            </div>
        </div>
    )
}

export default WeekDaysHolder;
